import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import OptionsSweep from "./images/Option_Sweeps1_noBG.png";
import NewsEarnings from "./images/news1_noBG.png";
import HowTo from "./images/help1_no_BG.png";
import LogoNameSlogan from "./images/logoAndText.png";
import ProfileSettings from "./images/Settings1_no_BG.png";
import Logout from "./images/logout_noBG.png";
import NameAndSlogan from "./images/nameAndSlogan.png";
import Hamburger from "./Hamburger";
import ReactDOM from "react-dom";
// import TagManager from "react-gtm-module";
import discord from "./images/discord_White.png";
import youtube from "./images/youtube_white.png";
import alert from "./images/alert_white.png";
import Banner from "./components/Banner";

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            error: "",
            status: "",
            windowDimension: null,
            drawer: false,
        };
        this.handleResize = this.handleResize.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
    }

    componentDidMount() {
        this.setState({ windowDimension: window.innerWidth });
        ReactDOM.findDOMNode(this).addEventListener(
            "resize",
            this.handleResize
        );
        ReactDOM.findDOMNode(this).addEventListener(
            "mousedown",
            this.closeDrawer
        );
    }

    handleResize = (event) => {
        console.log(window.innerWidth);

        this.setState({ windowDimension: window.innerWidth });
    };

    closeDrawer = (event) => {
        this.setState({ drawer: false });
    };

    render() {
        const {
            isAuthenticated,
            login,
            logout,
            // getPaymentStatus,
        } = this.props.auth;
        const isMobile = this.state.windowDimension <= 640;
        return (
            <>
                <nav ref="nv">
                    <ul id="examples">
                        {/* <div className="leftSide"> */}
                        {/* <div className="pseudoButton"> */}
                        {isAuthenticated() && !isMobile ? (
                            <div className="navRow">
                                <div className="optionsNavBar">
                                    <div className="optionsNavBarInner">
                                        <NavLink exact to="/Options">
                                            <img
                                                src={OptionsSweep}
                                                alt="OptionsSweep"
                                            />
                                            <p>Options Sweeps</p>
                                        </NavLink>
                                        <NavLink exact to="/news">
                                            <img
                                                src={NewsEarnings}
                                                alt="NewsAndEarnings"
                                            />
                                            <p>News</p>
                                        </NavLink>
                                        <a
                                            className="hover hover-3"
                                            href="https://www.chancetrades.com"
                                        >
                                            <img src={alert} alt="Discord" />
                                            <p>Trading Alerts</p>
                                        </a >
                                        <NavLink
                                            exact
                                            to="/HowTo"
                                            activeClassName="selected"
                                            className="hover hover-3"
                                        >
                                            <img src={HowTo} alt="Help" />
                                            <p>How-To-Use</p>
                                        </NavLink>
                                    </div>
                                </div>
                                <div>
                                    <NavLink exact to="/">
                                        <img
                                            className="optionsLogo"
                                            src={LogoNameSlogan}
                                            alt="Name_And_Slogan.png"
                                        />
                                    </NavLink>
                                </div>
                                <div className="optionsNavBar">
                                    <div className="optionsNavBarInner">
                                        <NavLink
                                            to={{
                                                pathname:
                                                    "https://discord.gg/g3H3Zu4",
                                            }}
                                            target="_blank"
                                        >
                                            <img src={discord} alt="Discord" />
                                            <p>Discord</p>
                                        </NavLink>
                                        <NavLink
                                            to={{
                                                pathname:
                                                    "https://www.youtube.com/c/TheChancesTaken",
                                            }}
                                            target="_blank"
                                        >
                                            <img src={youtube} alt="Discord" />
                                            <p>YouTube</p>
                                        </NavLink>
                                        <NavLink exact to="/Profile">
                                            <img
                                                src={ProfileSettings}
                                                alt="Account Settings"
                                            />
                                            <p>Account Settings</p>
                                        </NavLink>
                                        <button onClick={logout}>
                                            <img src={Logout} alt="Logout" />
                                            <p>Logout</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : !isAuthenticated() && !isMobile ? (
                            <div className="navBar">
                                <div className="navElement">
                                    <NavLink
                                        className="navButton"
                                        to={{
                                            pathname: "https://discord.gg/g3H3Zu4",
                                        }}
                                        target="_blank"
                                    >
                                        Discord
                                    </NavLink>
                                    <NavLink
                                        className="navButton"
                                        to={{
                                            pathname:
                                                "https://www.youtube.com/c/TheChancesTaken",
                                        }}
                                        target="_blank"
                                    >
                                        YouTube
                                    </NavLink>
                                    <a
                                        className="navButton"
                                        href="https://www.chancetrades.com"
                                    >
                                        Trading Alerts
                                    </a >
                                </div>

                                <img
                                    className="logoNav navElement"
                                    src={NameAndSlogan}
                                    alt="Name_And_Slogan.png"
                                />

                                <div className="navElement">
                                    <NavLink
                                        className="navButton"
                                        exact
                                        to="/HowTo"
                                    >
                                        How-To-Use
                                    </NavLink>
                                    <button className="navButton" onClick={login}>
                                        Login
                                    </button>
                                    <button
                                        className="navButton navSub"
                                        onClick={login}
                                    >
                                        Subscribe Now
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div
                                onClick={() =>
                                    this.setState({ drawer: !this.state.drawer })
                                }
                            >
                                <Hamburger
                                    auth={this.props.auth}
                                    isOpen={this.state.drawer}
                                />
                            </div>
                        )}
                    </ul>
                </nav>
                <Banner />
            </>
        );
    }
}

export default Nav;
