import { useEffect, useState } from "react";

export default function MinMaxFilter({ label, step, onChange }) {

    const [_min, setMin] = useState(-1);
    const [_max, setMax] = useState(-1);
    const [manual, setManual] = useState(false);

    useEffect(() => {
        if (!manual) {
            return
        }
        onChange(_min, _max);
    }, [_min, _max, manual, onChange])

    function setValue(e, set) {
        setManual(true);
        const value = Number(e.target.value);
        if (Number.isNaN(value))
            return;
        set(value);

    }

    return <div className="filterTabElement">
        <p>{label}:</p>
        <input type="number" style={{ width: "60px" }} onChange={e => setValue(e, setMin)} max={_max} step={step} /> to
        <input type="number" style={{ width: "60px", marginLeft: "10px" }} onChange={e => setValue(e, setMax)} min={_min} step={step} />
    </div>
}