import { CircularProgress, Box, Typography } from '@material-ui/core';

export default function CircularProgressWithLabel(
    props,
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', alignSelf: "center" }}>
            <CircularProgress variant="determinate" {...props} size="5rem" />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "24px" }}
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}