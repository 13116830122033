import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { axiosClient } from "./BASE_URL";
import axios from "axios";

const useStyles = (theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    display: "inline",
  },
  pos: {
    marginBottom: 12,
  },
  text: {
    display: "inline",
  },
  card: {
    borderRadius: "15px",
  },
});
// const CancelToken = axiosClient.CancelToken;
// const source = CancelToken.source();

class GetCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      cards: [],
    };
    this.getCards = this.getCards.bind(this);
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source();
    this.getCards();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Axios request canceled.");
  }

  async getCards() {
    const roles = this.state.profile["https://localhost:3000/roles"];
    var cust_id = null;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].startsWith("cus_")) {
        cust_id = roles[i];
        break;
      }
    }
    if (cust_id != null) {
      const payload = { cust_id: cust_id };
      const res = await axiosClient.post("/getcards/",
        payload,
        { cancelToken: this.axiosCancelSource.token }
      );
      this.setState({ cards: res.data.cards["data"] });
    } else {
      this.setState({ cards: [] });
    }
  }

  render() {
    const { classes } = this.props;
    const { cards } = this.state;
    var num = "";
    var expiry = "";
    try {
      for (let i = 0; i < cards.length; i++) {
        console.log("CARDS", cards[i]);
        num += cards[i]["card"]["last4"];
        expiry +=
          cards[i]["card"]["exp_month"] + "/" + cards[i]["card"]["exp_year"]; //other info we can get from cards[i]["card"] is "exp_month", "exp_year", "country"
      }
    } catch (error) {
      num = "";
      expiry = "";
    }
    return (
      <div>
        <p className="accountInfo accountDetailsInfo">
          {" "}
          <span className={classes.bullet}>••••</span> {num} Exp. {expiry}{" "}
        </p>
      </div>
    );
  }
}

export default withStyles(useStyles)(GetCards);
