import React, { Component } from "react";
import { axiosClient } from "./BASE_URL";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";


class NewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            error: "",
            status: "",
            Articles: [],
            historical: false,
            date: new Date(),
            page: 0,
            topic: "",
            ticker: props.location.aboutProps?.Ticker,
            popUp: false,
            clearable: "",
        };
    }

    componentDidMount() {
        this.loadUserProfile();
        this.renderArticles();
        setInterval(() => {
            this.loadUserProfile();
            this.renderArticles();
        }, 60000);
    }

    loadUserProfile() {
        if (this.props.auth.isAuthenticated()) {
            this.props.auth.getProfile((profile, error) =>
                this.setState({ profile, error })
            );
        } else {
            this.props.auth.login();
        }
    }

    renderArticles = async () => {
        try {
            const news_payload = {
                id: this.props.auth.userProfile.sub,
                //dateFrom:(moment(new Date(2021, 0, 1)).tz("America/Toronto").format("YYYY-MM-DD")), //
                //dateTo:(moment(this.date).tz("America/Toronto").format("YYYY-MM-DD")),
                ticker: this.state.ticker,
                topics: this.state.topic,
                page: this.state.page,
            };
            if (
                this.state.date.getFullYear() !== new Date().getFullYear() ||
                this.state.date.getMonth() !== new Date().getMonth() ||
                this.state.date.getDate() !== new Date().getDate()
            ) {
                news_payload["dateFrom"] = moment(this.state.date)
                    .tz("America/Toronto")
                    .format("YYYY-MM-DD");
                //(moment(new Date(2021, 0, 1)).tz("America/Toronto").format("YYYY-MM-DD"));
                news_payload["dateTo"] = moment(new Date())
                    .tz("America/Toronto")
                    .format("YYYY-MM-DD");
                //(moment(this.date).tz("America/Toronto").format("YYYY-MM-DD"));
            }
            const res = await axiosClient.post("/news", news_payload);
            const articles = res.data.news;

            this.setState({
                Articles: articles,
            });
        } catch (err) {
            console.log(err);
        }
    };

    changeRoute = () => {
        if (this.props.auth.isAuthenticated()) {
            this.props.history.push("profile");
        } else this.props.auth.login();
    };

    isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    buttonInput = ({ value, onClick }) => (
        <button className="newsDatePickerButton" onClick={onClick}>
            {value}
        </button>
    );

    pageDown = () => {
        if (this.state.page > 0)
            this.setState({ page: this.state.page - 1 }, this.renderArticles);
    };

    pageUp = () => {
        if (true)
            this.setState({ page: this.state.page + 1 }, this.renderArticles);
    };

    topicFilter = (e) => {
        this.setState({ topic: e.toString() }, this.renderArticles);
    };

    tickerFilter = (e) => {
        this.setState(
            { ticker: e.toString().toUpperCase() },
            this.renderArticles
        );
    };

    togglePopUp = () => {
        this.setState({ popUp: !this.state.popUp }, this.renderArticles);
    };
    clear = () => {
        document.getElementById("tickerInput").value = "";
        this.setState({ ticker: "" }, this.renderArticles);
    };

    render() {
        const article = this.state.Articles.map((a) => {
            // eslint-disable-next-line
            {
                var articleBody = "articleBody";

                if (a.stocks.length > 8) {
                    return (
                        <div
                            className="newsArticle"
                            onClick={() => window.open(a.url)}
                        >
                            <div className="articleImage">
                                {a.image.length > 0 && (
                                    <img
                                        className="articleImage"
                                        src={a.image[0]?.url}
                                        alt={a.image.url}
                                    ></img>
                                )}
                            </div>

                            {/* {(!a.image.length > 0) &&
                            <img className="articleImage" alt={a.image.url}></img>
                        } */}
                            <div className={articleBody}>
                                <p className="articleDate">
                                    {a.created.slice(0, 17)}
                                </p>
                                <h4 className="articleTitle articleElement">
                                    {a.title}
                                </h4>
                                {a.stocks.length > 0 && (
                                    <div className="articleKeywords articleElement">
                                        <button className="newsTicker">
                                            {a.stocks[0].name}
                                        </button>
                                        <button className="newsTicker">
                                            {a.stocks[1].name}
                                        </button>
                                        <button className="newsTicker">
                                            {a.stocks[2].name}
                                        </button>
                                        <button className="newsTicker">
                                            {a.stocks[3].name}
                                        </button>
                                        <button className="newsTicker">
                                            {a.stocks[4].name}
                                        </button>
                                        <button className="newsTicker">
                                            {a.stocks[5].name}
                                        </button>
                                        <button className="newsTicker">
                                            +
                                        </button>
                                    </div>
                                )}
                                <p className="articleDescription articleElement">
                                    {a.teaser}
                                </p>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="newsArticle"
                            onClick={() => window.open(a.url)}
                        >
                            <div className="articleImage">
                                {a.image.length > 0 && (
                                    <img
                                        className="articleImage"
                                        src={a.image[0]?.url}
                                        alt={a.image.url}
                                    ></img>
                                )}
                            </div>
                            {/* {a.image.length > 0 && (
                
              )}
              {/* {(!a.image.length > 0) &&
                            <img className="articleImage" alt={a.image.url}></img>
                        } */}
                            <div className={articleBody}>
                                <p className="articleDate">
                                    {a.created.slice(0, 17)}
                                </p>

                                <h4 className="articleTitle articleElement">
                                    {a.title}
                                </h4>
                                {a.stocks.length > 0 && (
                                    <div className="articleKeywords articleElement">
                                        {a.stocks.map((b) => (
                                            <button className="newsTicker">
                                                {b.name}
                                            </button>
                                        ))}
                                    </div>
                                )}
                                <p className="articleDescription articleElement">
                                    {a.teaser}
                                </p>
                            </div>
                        </div>
                    );
                }
            }
        });
        return (
            <div className="newsFeed">
                <div className="newsFilters">
                    <button className="newsFilterButton newsFilterElement">
                        <DatePicker
                            dateFormat="y-MM-dd"
                            selected={this.state.date}
                            onChange={(date) =>
                                this.setState(
                                    {
                                        date: date,
                                    },
                                    this.renderArticles
                                )
                            }
                            filterDate={this.isWeekday}
                            minDate={new Date(2021, 0, 1)}
                            maxDate={Date.now()}
                            customInput={<this.buttonInput />}
                        />
                    </button>
                    <button
                        className="newsFilterButton newsFilterElement"
                        onClick={this.clear}
                    >
                        All Tickers
                    </button>
                    <div className="newsFilterSearchbar newsFilterElement">
                        <p className="newsFilterSearchbarElement newsSearchText">
                            Tickers:
                        </p>
                        <input
                            type="text"
                            id="tickerInput"
                            className="newsFilterSearchbarElement newsSearchbar"
                            onChange={(event) =>
                                this.tickerFilter(event.target.value)
                            }
                        ></input>
                    </div>
                    <div className="newsFilterSearchbar newsFilterElement">
                        <p className="newsFilterSearchbarElement newsSearchText">
                            Keywords:
                        </p>
                        <input
                            type="text"
                            className="newsFilterSearchbarElement newsSearchbar"
                            onChange={(event) =>
                                this.topicFilter(event.target.value)
                            }
                        ></input>
                    </div>
                </div>

                <div className="newsArticles">
                    {article}
                    {/* {console.log(moment(this.date).tz("America/Toronto").format("YYYY-MM-DD"))} */}
                </div>
                <div className="pageSelect">
                    <button className="prevPage" onClick={this.pageDown}>
                        {" "}
                        &laquo; Previous{" "}
                    </button>
                    <p>{this.state.page + 1}</p>
                    <button className="nextPage" onClick={this.pageUp}>
                        {" "}
                        Next &raquo;{" "}
                    </button>
                </div>
            </div>
        );
    }
}
export default NewsFeed;