import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import NewsFeed from "./NewsFeed";
import EarningReports from "./EarningReports";

class NewsEarnings extends Component {

    render() {
        return (
            <>
                <div className="newsEarningsPage">
                    <div className="newsEarningsHeader">
                        <h3 className="newsHeader">Recent News</h3>
                        <h3 className="earningsHeader">Earning Reports</h3>
                    </div>
                    <div className="newsEarningBody">
                        <NewsFeed auth={this.props.auth} {...this.props} />
                        <EarningReports auth={this.props.auth} {...this.props} />
                    </div>
                </div>
            </>
        );
    }
}

export default function NewsEarningsWithLocation({ auth }) {
    const location = useLocation();
    return <NewsEarnings auth={auth} location={location} />
}
