import auth0 from "auth0-js";
import { FRONTEND_BASE_URL } from "../BASE_URL";

export default class Auth {
  constructor(navigate) {
    this.userProfile = null;
    this.navigate = navigate;
    // this.user = props.auth0.user;
    // console.log({ user: this.user })
    this.hasUserLoggedIn = sessionStorage.getItem("hasUserLoggedIn") || false;
    const redirectUri = `${window.location.origin}/callback`
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri,
      responseType: "token id_token",
      scope: "openid profile email",
    });
  }

  login = async () => {
    await this.auth0.authorize();
    this.navigate("/options");
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.hasUserLoggedIn = true;
        sessionStorage.setItem("hasUserLoggedIn", this.hasUserLoggedIn);
        console.log("[AUTH] Logged in user successfully!")
        this.navigate("/");
      } else if (err) {
        this.navigate("/");
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  };

  setSession = (authResult) => {
    // set the time that the access token will expire
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
  };

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    if (expiresAt) {
      return new Date().getTime() < expiresAt;
    }
    return false
  }

  logout = () => {
    this.hasUserLoggedIn = false;
    sessionStorage.removeItem("hasUserLoggedIn");
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    this.userProfile = null;
    this.auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: FRONTEND_BASE_URL,
    });
  };

  redirectToLoginAfterLogout() {
    this.logout();
    this.navigate('/login')
  }

  getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("No access token found.");
    }
    return accessToken;
  };

  getProfile = (cb) => {
    if (this.userProfile) return cb(this.userProfile);

    this.auth0.client.userInfo(this.getAccessToken(), (err, profile) => {
      console.log({ profile })
      if (err)
        console.log(err)
      if (profile) this.userProfile = profile;
      console.log(profile);
      cb(profile, err);
    });
  };
  getPaymentStatus = () => {
    try {
      if (this.userProfile != null) {
        if (this.userProfile["https://localhost:3000/roles"][0] === "paying") {
          return true;
        }
      }
    } catch (err) { }
    return false;
  };

  getCusStatus = () => {
    try {
      if (this.userProfile != null) {
        if (
          this.userProfile["https://localhost:3000/roles"][1].includes("cus")
        ) {
          return true;
        }
      }
    } catch (err) { }
    return false;
  };
}