export class Speech {
    constructor() {
        var voices = speechSynthesis.getVoices();
        var voice = -1;
        for (let j = 0; j < voices.length; j++) {
            if (
                voices[j].name === "Samantha" ||
                voices[j].name === "Google US English"
            ) {
                voice = j;
                break;
            }
        }
        this.voice = voice;
    }

    talk(message) {
        const speech = new SpeechSynthesisUtterance(message);
        if (this.voice !== -1)
            speech.voice = speechSynthesis.getVoices()[
                this.voice
            ];
        //speech.rate = 0.7;
        speech.lang = "en-US";

        speechSynthesis.speak(speech);
    }
}