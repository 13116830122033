import React, { Component } from "react";

import GetSubscriptionStatus from "./GetSubscriptionStatus";
import GetCards from "./GetCards";
import NewSubscriptionPage from "./NewSubscriptionPage";
import EditDefaultCardPage from "./EditDefaultCardPage";
import GetSubscriptionOptions from "./SelectedSubscription.js";

import Amex from "./images/cards/amex.png";
import Disc from "./images/cards/discover.png";
import Mast from "./images/cards/mastercard.png";
import Visa from "./images/cards/visa.png";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      error: "",
      status: "",
      edit: false,
      billDate: "",
    };
  }

  async componentDidMount() {
    await this.loadUserProfile().then((response) => {
      console.log("profile 1", this.state.profile);
    });
  }

  async loadUserProfile() {
    console.log(this.props.auth);
    await this.props.auth.getProfile((profile, error) =>
      this.setState({ profile, error })
    );
    return true;
  }

  openEdit = () => {
    this.setState({ edit: true });
  };

  closeEdit = () => {
    this.setState({ edit: false });
  };
  render() {
    const { profile } = this.state;
    if (!profile) return null;

    if (this.props.auth.getCusStatus() && this.props.auth.getPaymentStatus()) {
      return (
        <>
          <div className={"flex-container-profile"}>
            {this.props.auth.getCusStatus() ? (
              <>
                <GetSubscriptionOptions
                  profile={profile}
                  auth={this.props.auth}
                />

                <div className={"flex-column"}>
                  <div className={"flex-column-header"}>Account Details</div>
                  <div className="accountFullRow">
                    <p className="accountHeader">Account Email</p>
                    <p className="accountInfo accountDetailsInfo">
                      {/* <GetCards profile={profile}></GetCards> */}
                      {this.state.profile.email}
                    </p>
                  </div>

                  <div className="accountFullRow">
                    <p className="accountHeader">Account Name</p>
                    <p className="accountInfo accountDetailsInfo">
                      {this.state.profile.name}
                    </p>
                  </div>

                  <div className="accountCardsRow">
                    <div className="accountCard">
                      <img src={Amex} alt="Amex" />
                    </div>
                    <div className="accountCard">
                      <img src={Disc} alt="Disc" />
                    </div>
                    <div className="accountCard">
                      <img src={Mast} alt="Mast" />
                    </div>
                    <div className="accountCard">
                      <img src={Visa} alt="Visa" />
                    </div>
                  </div>

                  {this.state.edit ? (
                    <>
                      <EditDefaultCardPage
                        profile={profile}
                        refreshPage={this.props.refreshPage}
                        closeEdit={this.closeEdit}
                      ></EditDefaultCardPage>
                    </>
                  ) : (
                    <div>
                      <div className="accountFullRow">
                        {/* <div className='accountMajorityRow'> */}
                        <p className="accountHeader">Card Number</p>
                        <p className="accountInfo accountDetailsInfo">
                          <GetCards profile={profile}></GetCards>
                        </p>
                        {/* </div> */}
                      </div>

                      <div className="accountFullRow">
                        {/* <div className='accountMajorityRow'> */}
                        <p className="accountHeader">Next Billing Date</p>
                        <p className="accountInfo accountDetailsInfo">
                          <GetSubscriptionStatus profile={profile} />
                        </p>
                        {/* </div> */}
                      </div>

                      <div className="accountFullRow">
                        <button
                          className="accountRight"
                          onClick={this.openEdit}
                        >
                          Edit Details
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      );
    } else {
      return <NewSubscriptionPage auth={this.props.auth} />;
    }
  }
}

export default Profile;
