import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const mystyle = {
  paddingLeft: "5%",
  paddingRight: "5%",
  paddingTop: "5%",
  paddingBottom: "5%",
  fontFamily: "Arial",
  border: "1px solid lightgrey",
};

export default function CardInputNewSub() {
  return (
    <div style={mystyle}>
      <CardElement options={CARD_ELEMENT_OPTIONS}></CardElement>
    </div>
  );
}
