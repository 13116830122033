import React from "react";
import moment from "moment-timezone";
import { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { axiosClient } from "./BASE_URL";
import SubNewsFeed from "./SubNewsFeed";
import WatchlistToggle from "./images/back_button.png";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { Modal } from "react-responsive-modal";
import { utils, writeFileXLSX } from "xlsx"
import { FilterController } from "./components/filter/Controller";
import MinMaxFilter from "./components/filter/MinMaxFilter";
import { Button, CircularProgress } from "@material-ui/core";
import EventNotification from "./components/EventTimer";
import OptionsWatchListDrawer, { OptionsWatchlist } from "./components/options/OptionWatchlist";
import CircularProgressWithLabel from "./components/progress/CircularProgressWithLabel";
import axios from "axios";
import { Speech } from "./components/speech";
import { Table, tableColumns } from "./components/options/Table";

var myHeaders = new Headers();
myHeaders.append("Accept", "application/json");


async function loadEconomicEvents() {
    const resp = await axiosClient.get("/economic_events")
    return resp.data['events']
}

async function getRowCount(filters, userId) {
    const payload = { filters };
    const resp = await axiosClient.post("/get_row_count", payload, { params: { id: userId } });
    return resp.data['count'];
}

async function newFetchData(id, filters, oldData, isAdvDate, fetchLatest, controlSignal, calloutGoldenSweeps, offset = 0) {
    const payload = { filters };
    var dataTop, putFlow, callFlow, sentiment, updated;

    const promises = [];

    const lastUpdated = oldData[0]?.updated;

    let oldUpdateIndex = 0;
    let newUpdateIndex = -1;
    if (fetchLatest && lastUpdated) {
        console.log(`[newFetch] Fetching earlier data`);
        promises.push(
            axiosClient.get("/get_latest_data", { params: { id, last_updated: lastUpdated, batch_entries: !isAdvDate }, signal: controlSignal })
        )
        oldUpdateIndex = 1;
        newUpdateIndex = 0;
    }
    const finalOffset = isAdvDate ? 0 : offset;
    promises.push(
        axiosClient.post("/getData", payload, { params: { id, offset: finalOffset, batch_entries: !isAdvDate }, signal: controlSignal })
    )
    const dataArr = await Promise.all(promises);
    console.log(
        'date arr before', oldData.length
    )
    dataArr.forEach((resp) => {
        for (let i = 0; i <= resp.data.items.length; i++) {
            if (resp.data.items[i])
                oldData.push(resp.data.items[i]);
        }
    });
    console.log('after data arr', oldData.length)
    console.time('[newFetch] process data',);
    const processed_data = await processData(oldData, isAdvDate, calloutGoldenSweeps)
    console.timeEnd('[newFetch] process data');

    const up_meme = processed_data[0];
    dataTop = processed_data[1];
    putFlow = processed_data[2];
    callFlow = processed_data[3];
    sentiment = processed_data[4];
    const CaP = [processed_data[5], processed_data[6]];
    const oldDataLength = dataArr[oldUpdateIndex]?.data?.items?.length || 0;
    const newDataLength = dataArr?.[newUpdateIndex]?.data?.items?.length || 0;
    const totalLength = oldDataLength + newDataLength;
    const finalLength = oldData.length + totalLength;

    // sort descending order

    return [up_meme, dataTop, putFlow, callFlow, sentiment, updated, finalLength, CaP, oldDataLength];
}


// Calculate and format monetary values
const formatMonetaryValue = (value) => {
    if (value >= 1000000000) {
        return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M";
    } else {
        return (value / 1000).toFixed(1) + "k";
    }
};

function getRowId(row) {
    return row.id
}

let earningsCache = {};
async function getEarningsForTickers(tickers) {
    const availableTickers = Object.keys(earningsCache);
    const missingTickers = tickers.filter(ticker => !availableTickers.includes(ticker));
    if (missingTickers.length === 0) {
        return earningsCache;
    }
    const payload = {
        tickers: missingTickers
    }
    const resp = await axiosClient.post("/earnings/period", payload)
    const data = resp.data;
    // copy data to cache
    Object.assign(earningsCache, data);
    return earningsCache;
}

async function processData(meme, isDate = false, calloutGoldenSweeps = false) {
    meme = meme.filter(row => row);
    let orderedData = {};
    let callPrem = 0;
    let putPrem = 0;
    let bullCall = 0;
    let numCall = 0;
    let numPut = 0;
    let bullPut = 0;

    const keys = new Set();
    const tickers = new Set();
    const speaker = new Speech();

    for (const entry of meme) {
        const rowId = getRowId(entry);
        if (keys.has(rowId)) {
            continue;
        }
        keys.add(rowId);
        if (!entry.preprocessed) {
            // Pre-processing for new data
            if (isDate) {
                entry.time = `${moment(entry.date).format('Do MMM')} ${entry.time}`;
            }

            if (calloutGoldenSweeps && entry.chan_filter === 'GOLDEN') {
                const expiration = new Date(entry.date_expiration);
                const message = entry.put_call +
                    " " +
                    entry.ticker +
                    ". " +
                    parseFloat(entry.strike_price) +
                    ". " +
                    expiration.toLocaleString("default", {
                        month: "long",
                    }) +
                    " " +
                    expiration.getDate() +
                    ". " +
                    parseFloat(entry.cost_basis) +
                    ". " +
                    entry.size +
                    " contracts. chance A I " +
                    entry.aggressor_ind
                speaker.talk(message)
            }

            entry.preprocessed = true;
        }

        tickers.add(entry.ticker);
        // Update statistics for new data
        if (entry.put_call === "CALL") {
            callPrem += Number(entry.cost_basis);
            numCall += Number(entry.size);
            if (entry.underlying_type !== "ETF") {
                bullCall += Number(entry.cost_basis);
            }
        } else {
            putPrem += Number(entry.cost_basis);
            numPut += Number(entry.size);
            if (entry.underlying_type !== "ETF") {
                bullPut += Number(entry.cost_basis);
            }
        }

        orderedData[entry.ticker] = orderedData[entry.ticker] || {
            TICKER: entry.ticker,
            SWEEPS: 0,
            PUT: 0,
            CALL: 0,
        };
        orderedData[entry.ticker].SWEEPS += 1;
        if (entry.put_call === "PUT") {
            orderedData[entry.ticker].PUT += 1;
        } else if (entry.put_call === "CALL") {
            orderedData[entry.ticker].CALL += 1;
        }
    }

    // populate the earnings field
    const tickerMap = await getEarningsForTickers(Array.from(tickers));
    for (const entry of meme) {
        const date = tickerMap[entry.ticker]
        if (!date)
            continue;
        entry.earnings_date = date;
    }
    // Filter newArr based on the SWEEPS count
    const newArr = Object.values(orderedData).filter(item => item.SWEEPS > 4);

    const putTotal = formatMonetaryValue(putPrem);
    const callTotal = formatMonetaryValue(callPrem);

    const putFlow = `${formatMonetaryValue(numPut)} @ $${putTotal}`;
    const callFlow = `${formatMonetaryValue(numCall)} @ $${callTotal}`;
    // Determine sentiment
    let sentiment = "Neutral";
    if (bullPut > bullCall * 1.1) {
        sentiment = "Bearish";
    } else if (bullCall > bullPut * 1.1) {
        sentiment = "Bullish";
    }


    return [meme, newArr.slice(0, 50), putFlow, callFlow, sentiment, bullCall, bullPut];
}

function populateCurrentTickers(data) {
    return (
        <button
            className="tickerFilter"
            onClick={() => this.removeFromWatchList(data)}
        >
            {data}
        </button>
    );
}

class OptionsTable extends Component {
    FETCH_DATA_INTERVAL = 5000;
    intervalID;
    timezone = "America/Toronto";
    tableInstance = React.createRef();
    // constructor(props) {
    //   super(props);
    // }
    tableHtmlRef = React.createRef();

    transactionId = ''
    controller = new AbortController();
    state = {
        events: [],
        timeEstimate: {
            time: '',
            estimate: 0
        },
        optionsWatchlistEnabled: false,
        data: [],
        options_watchlist: [],
        rawData: [],
        rawOldData: [],
        firstCallLock: false,
        filters: {
            "eqDate": this.getLatestTradingDay()
        },
        priceFilter: [0, 500000],
        firstLoad: true,
        dataTop: [],
        profile: null,
        error: "",
        putFlow: null,
        callFlow: null,
        currentCount: [0],
        sentiment: "",
        updated: "",
        historical: false,
        date: this.getLatestTradingDay(),
        tickerF: null,
        watchlist: [],
        watchlistTab: true,
        watchlistStatus: "Off",
        watchlistOpen: false,
        quickWatchlistEmpty: true,
        calloutToggle: false,
        all: true,
        firstRound: true,
        open: false,
        modalTicker: "",
        quick: "",
        hidden: ["put_call", "chan_filter", "sentiment_flipped", "earnings_date", "date"],
        CaP: [1, 1],
        dateFilterToggle: false,
        watchlistFocus: false,
        glow: true,
        pulse: false,
        isMarketOpen: true,
        dailyCount: 0,
        offset: 0,
    };

    optionsWatchlist = new OptionsWatchlist(this.props.auth.userProfile.sub, (updateObj) => this.setState(updateObj));

    isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    watchlistToggle = () => {
        this.setState({ glow: false });
        this.setState({ watchlistOpen: !this.state.watchlistOpen });
    };

    componentDidMount() {
        this.loadUserProfile();

        this.firstLoad();
        this.loadEconCalendar();

        if (window.innerWidth > 1100)
            this.setState({ watchlistOpen: !this.state.watchlistOpen });
        //this.pulser();
    }

    componentWillUnmount() {
        this.controller.abort();
    }

    async loadUserProfile() {
        // load user options watchlist
        const options = await this.optionsWatchlist.getWatchlist();
        await this.optionsWatchlist.fetchUpdates();
        this.setState({ options_watchlist: options });
        console.log('option watchlist updates')
        console.log(this.state.options_watchlist_updates)

        this.props.auth.getProfile((profile, error) =>
            this.setState({ profile, error })
        );
    }

    async loadEconCalendar() {
        const econEvents = await loadEconomicEvents();
        this.setState({ events: econEvents });
    }

    buttonInput = ({ value, onClick }) => (
        <button className="button in-pricing goldenButton" onClick={onClick}>
            {value}
        </button>
    );

    async firstLoad() {
        const transactionId = crypto.randomUUID();
        this.transactionId = transactionId;
        this.newGetData(transactionId, true);
    }
    async setIsMarketOpen() {
        let newMarketStatus = this.state.isMarketOpen;
        var m = moment().tz("America/New_York");
        if (this.state.historical) {
            newMarketStatus = false;
        } else {
            if (m.day() < 1 || m.day() > 5 || m.hour() >= 17 || m.hour() < 9) {
                newMarketStatus = false;
            } else {
                newMarketStatus = true;
            }
        }
        if (this.state.isMarketOpen !== newMarketStatus) {
            if (m.hour() === 9 && !this.state.historical && newMarketStatus) {
                await this.setState({
                    data: [
                        {
                            time: "loading",
                            date: "loading",
                            ticker: "loading",
                            put_call: "loading",
                            cost_basis: "loading",
                            strike_price: "loading",
                            date_expiration: "loading",
                            open_interest: "loading",
                            volume: "loading",
                            price: "loading",
                            spot: "loading",
                        },
                    ],
                });
            }
            await this.setState({
                isMarketOpen: newMarketStatus,
            });
        }
    }

    allData = []

    addCustomFilter(col, operation, value) {
        console.log({ col, operation, value });
        const filters = this.state.filters;
        const key = `${operation}-${col}`
        filters[key] = value;
        this.setState({ filters })
    }

    addMinMaxFilter(col, min, max) {
        const isAdvDate = this.state.dateFilterToggle;
        if (isAdvDate) {
            if (min != null && min !== -1) {
                this.addCustomFilter(col, 'gte', min);
            }
            if (max != null && max !== -1) {
                this.addCustomFilter(col, 'lte', max);
            }
        }
        else {
            this.tableInstance.current.setFilter(col, [min, max]);
        }
    }

    MAX_ALLOWED_ROWS = 500_000
    SECONDS_PER_THOUSAND_ROWS = 0.12

    async newGetData(transactionId, isFirstLoad) {

        this.setState({ loadingStatus: "LOADING" });
        console.log('[newData] Starting fetching for', transactionId);
        const a = performance.now();
        const filters = this.getCurrentFilters();

        // Handle Date range filter
        const { isToday, isAdvDate } = this.getDateCases(filters);
        const abortSignal = this.getAbortSignal();

        const offset = isFirstLoad ? 0 : this.state.offset;
        await this.setIsMarketOpen();
        const fetchLatest = isToday && this.state.isMarketOpen;
        const userId = this.props.auth.userProfile.sub;

        try {
            const range = this.getCurrentDateRange();
            if (isAdvDate && !range.maxDate && !range.minDate)
                return;
            
            if (isAdvDate) {
                // if there is a quick ticker filter, convert it into a custom filter.
                if (this.state.quick) {
                    this.addCustomFilter("ticker", "eq", this.state.quick);
                }
                const rowCount = await getRowCount(filters, userId);
                if (rowCount >= this.MAX_ALLOWED_ROWS) {
                    alert("The given filter is too broad, please be more specific in the filters. This filter returns an amount of data that cannot be processed by your machine.")
                    return;
                }
                const startTime = new Date();
                const estimate = (rowCount / 1000) * this.SECONDS_PER_THOUSAND_ROWS;
                console.log('estimate', { estimate })
                const intervalId = setInterval(() => {
                    const currentTime = new Date();
                    const currentSeconds = moment(currentTime).diff(startTime, 'seconds')
                    const percentage = Math.floor((currentSeconds / estimate) * 100); // account for state update 
                    if (percentage >= 100) {
                        clearInterval(intervalId);
                        return
                    }
                    this.setState({ timeEstimate: { time: currentTime, percentage, estimate } })
                }, 1000);
            }
            const data = await newFetchData(userId, filters, this.state.data, isAdvDate, fetchLatest, abortSignal, this.state.calloutToggle, offset);

            this.setState({ loadingStatus: "Loaded. Processing to add more information, please wait a few more seconds. . ." });
            if (this.transactionId !== transactionId) {
                console.log('A newer transaction is in place, destroying current transaction');
                return;
            }
            this.optionsWatchlist.rerenderUpdate();
            this.setState((prevState) => {
                let newData = [];
                if (isAdvDate) {
                    newData = data[0];
                } else {
                    newData = [...data[0]];
                }
                const newOffset = prevState.offset + data[8];
                const dateFormat = isAdvDate ? "Do MMM h:mm:ss A" : "h:mm:ss A";
                if (!isAdvDate)
                    newData.sort((a, b) => {
                        const aTime = moment(a.time, dateFormat);
                        const bTime = moment(b.time, dateFormat);
                        return bTime.diff(aTime);
                    });
                return {
                    data: newData,
                    dataTop: [...data[1]],
                    putFlow: [...data[2]],
                    callFlow: [...data[3]],
                    sentiment: [...data[4]],
                    updated: data[5],
                    currentCount: data[6],
                    CaP: [...data[7]],
                    offset: newOffset
                }
            })
            if (data.length === 0) {
                this.setState({ loadingStatus: "NO DATA FOUND" })
            }
            this.setState({ loadingStatus: "loaded" });
            const b = performance.now();
            const interval = (b - a).toFixed(2);
            console.log(`[newGet] Finished updating data. took ${interval}ms`)
            if (isAdvDate || this.state.dateFilterToggle) {
                return
            }
            this.intervalID = setTimeout(() => this.newGetData(transactionId, false), this.FETCH_DATA_INTERVAL);
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Request was cancelled.');
                return;
            }
            this.setState({ loadingStatus: "errored" });
        } finally {
            this.setState({ timeEstimate: { percentage: 0, estimate: 0 } })
        }
    }

    /**
     * Use before making a network call to prevent simulatneous calls
     */
    getAbortSignal() {
        if (this.state.firstCallLock) {
            this.controller.abort("Incoming new fetch request");
            this.controller = new AbortController();
            return this.controller.signal;
        }
        this.setState({ firstCallLock: true });
        return this.controller.signal
    }

    releaseLock() {
        this.setState({ firstCallLock: false });
    }

    getDateString(date) {
        const timezone = "America/Toronto";
        const format = "YYYY-MM-DD";
        return moment(date).tz(timezone).format(format);
    }

    handleDateFilterChange(key, date) {
        const dateString = this.getDateString(date);
        this.handleFilterChange(key, dateString);
    }

    reset() {
        if (this.intervalID) {
            clearTimeout(this.intervalID);
        }
    }

    resetAndGetData() {
        this.reset();
        this.setState({
            historical: true,
            firstLoad: true,
            isMarketOpen: true,
            offset: 0,
            data: [],
        });
        this.firstLoad();
    }

    handleFilterChange(key, value) {
        const filters = this.state.filters;
        filters[key] = value;
        this.setState({ filters });
        // only reload content if changed filter is date and advanced filter is off.
        if (key === 'eqDate') {
            this.resetAndGetData();
        }
    }

    getWatchlistData = async (event) => {
        let currStatus = this.state.watchlistStatus;
        let w = [];
        if (currStatus === "Off") {
            let profileId = this.state.profile.sub;
            await this.quickFilter("");
            let res = await axiosClient.get("/get_user?id=" + profileId);
            let status = res.status;
            while (status !== 200) {
                res = await axiosClient.get("/get_user?id=" + profileId);
                status = res.status;
            }
            w = res.data.watchlist;
            this.setState({ watchlistStatus: "On" });
        } else {
            w = [];
            this.setState({ watchlistStatus: "Off" });
        }

        await this.setState({ watchlist: w });
        const filter = { type: "watchlist", value: w }
        if (w.length !== 0) this.tableInstance.current.setFilter("ticker", filter);
        else this.tableInstance.current.setFilter("ticker", undefined);
    };

    getTimezoneDate(date) {
        return new Date(date.toLocaleString("en-US", { timeZone: this.timezone }));
    }

    getLatestTradingDay() {
        const currentDate = this.getTimezoneDate(new Date());
        const day = currentDate.getDay();
        const nineThirtyAM = new Date(currentDate);
        nineThirtyAM.setHours(9);
        nineThirtyAM.setMinutes(30);
        const isCurrent = currentDate.getTime() > nineThirtyAM.getTime()
        if (day === 0 || day === 6 || !isCurrent) {
            const yesterday = new Date(currentDate);
            let subtractDays = day === 0 ? 2 : 1;
            if (day === 1)
                subtractDays = 3
            yesterday.setDate(yesterday.getDate() - subtractDays);
            return yesterday;
        }
        return currentDate;
    }

    addToWatchList = async (ticker) => {
        // if (this.state.quickWatchlistEmpty) {
        let w = this.state.watchlist;
        if (this.state.watchlistStatus === "On") {
            if (!w.includes(ticker)) {
                w.push(ticker);
                await this.setState({ watchlist: w });
                let res = await axiosClient.request({
                    method: "POST",
                    url: "/update_watchlist",
                    data: {
                        id: this.state.profile.sub,
                        watchlist: this.state.watchlist,
                    },
                });
                let status = res.status;
                while (status !== 200) {
                    res = await axiosClient.request({
                        method: "POST",
                        url: "/update_watchlist",
                        data: {
                            id: this.state.profile.sub,
                            watchlist: this.state.watchlist,
                        },
                    });
                    status = res.status;
                }
                const filter = { type: "watchlist", value: w }
                this.tableInstance.current.setFilter("ticker", filter);
            }
        }
        //}
    };

    toggleDateFilter() {
        const val = !this.state.dateFilterToggle;
        this.setState({ dateFilterToggle: val });
        this.reset();
        // retrigger quick filter to convert it into a column filter.
        if (val) {
            this.quickFilter(this.state.quick, true);
        }
    }

    toggleCheckbox = async (event) => {
        let selected = this.state.hidden;
        let index = selected.indexOf(event);
        if (index !== -1) {
            selected.splice(index, 1);
        } else {
            selected.push(event);
        }
        await this.setState({ hidden: selected });
    };

    quickFilter = (ticker, forceAdvDate = false) => {
        const isAdvDate = forceAdvDate || this.isAdvDate();
        console.log('isAdvDate', isAdvDate);
        if (isAdvDate) {
            this.setState({ quick: ticker });
            this.addCustomFilter('ticker', 'eq', ticker);
            return;
        }
        if (ticker.length === 0) {
            this.setState({ quick: "", quickWatchlistEmpty: true });
            this.tableInstance.current.setFilter("ticker", undefined);
        } else {
            this.setState({ quick: ticker, quickWatchlistEmpty: false, watchlistStatus: "Off", watchlist: [] });
            const filter = { type: "quick", value: ticker }
            this.tableInstance.current.setFilter("ticker", filter);
        }
    };

    onOpenModal = (ticker) => {
        this.setState({ modalTicker: ticker });
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    getCurrentDateRange() {
        const filters = this.state.filters;
        const dateToggle = this.state.dateFilterToggle;
        if (!dateToggle) {
            return {
                minDate: this.state.date,
                maxDate: this.state.date
            }
        }
        const minDate = filters['minDate']
        const maxDate = filters['maxDate']
        return {
            minDate: minDate ? new Date(minDate) : undefined,
            maxDate: maxDate ? new Date(maxDate) : undefined
        }
    }

    getDateCases(filters) {
        const isDate = filters.some(filter => filter.column === "date");
        let isToday = false;
        const today = this.getLatestTradingDay();
        console.log('Latest day:')
        console.log({ today })
        if (isDate) {
            const today = this.getLatestTradingDay();
            const todayString = this.getDateString(today);
            isToday = filters.some(filter => filter.column === "date" && filter.value === todayString);
        }
        // const isAdvDate = filters.some(filter => filter.column === "date" && filter.operator !== "eq");
        const isAdvDate = this.state.dateFilterToggle;
        return { isToday, isDate, isAdvDate };
    }

    isAdvDate() {
        return this.state.dateFilterToggle;
    }

    getCurrentFilters() {

        const filters = this.state.filters;
        const keys = Object.keys(filters);

        const keyMap = {
            'minDate': {
                column: 'date',
                operator: 'gte',
                toggle: this.state.dateFilterToggle
            },
            'maxDate': {
                column: 'date',
                operator: 'lte',
                toggle: this.state.dateFilterToggle
            },
            'eqDate': {
                'column': 'date',
                'operator': 'eq',
                toggle: !this.state.dateFilterToggle,
            }
        }

        const finalFilters = [];

        keys.forEach(key => {
            let map = keyMap[key]
            if (!map) {
                const parts = key.split("-");
                map = {
                    column: parts[1],
                    operator: parts[0],
                    toggle: true
                }
            }
            map.value = filters[key]
            if (!map.toggle || !map.value) {
                console.log('skipping ', key)
                    ; return
            }
            let value = map.value;
            if (value instanceof Date) {
                map.value = this.getDateString(value)
            } else {
                map.value = value
            }
            finalFilters.push(map)
        })

        return finalFilters
    }

    removeFromWatchList = async (ticker) => {
        let w = this.state.watchlist;
        if (w.includes(ticker)) {
            w = w.filter(function (ele) {
                // eslint-disable-next-line
                return ele !== ticker;
            });
            let res = await axiosClient.request({
                method: "POST",
                url: "/update_watchlist",
                data: {
                    id: this.state.profile.sub,
                    watchlist: w,
                },
            });
            let status = res.status;
            while (status !== 200) {
                res = await axiosClient.request({
                    method: "POST",
                    url: "/update_watchlist",
                    data: {
                        id: this.state.profile.sub,
                        watchlist: w,
                    },
                });
                status = res.status;
            }
            await this.setState({ watchlist: w });
        }
        if (w.length !== 0) {
            const filter = { type: "watchlist", value: w }
            this.tableInstance.current.setFilter("ticker", filter);
        } else this.tableInstance.current.setFilter("ticker", undefined);
    };

    watchlistOffClick = () => {
        this.setState({ watchlistOpen: false });
        this.setState({ watchlistFocus: false });
    };

    dismissEventNotification(index) {
        console.log('dismissEventNotification', index)
        const event = this.state.events[index];
        event.dismissed = true;
        const newEvents = this.state.events.map((oldEvent, i) => index === i ? event : oldEvent);
        this.setState({ events: newEvents });
    }

    downloadExcel() {
        const table = this.tableInstance.current;

        var workbook = utils.book_new();
        const data = table.filteredRows.map(row => {
            const newRow = {};
            tableColumns.forEach(column => {
                newRow[column.Header] = row.values[column.accessor]
            })
            return newRow
        })
        const sheet = utils.json_to_sheet(data)
        utils.book_append_sheet(workbook, sheet, "Sheet1");
        writeFileXLSX(workbook, "Report.xlsx");
    }

    toggleOptionsWatchlistToggle() {
        this.setState({ optionsWatchlistEnabled: !this.state.optionsWatchlistEnabled })
    }

    async addRowToOptionsWatchlist(optionRow) {
        await this.optionsWatchlist.addOption(optionRow)
    }

    render() {
        var watchlistSidebar = this.state.watchlistOpen
            ? "watchlistSidebar open"
            : "watchlistSidebar";
        var stockOptionsTable = this.state.watchlistOpen
            ? "stockOptionsTable open"
            : "stockOptionsTable";
        var watchlistToggle = this.state.watchlistOpen
            ? "watchlistToggle open"
            : "watchlistToggle";
        var watchlistFocus =
            this.state.watchlistOpen || window.innerWidth > 1100
                ? "watchlistFocus"
                : "";
        var toggleGlow = this.state.glow ? "toggleGlow" : "";

        const filterController = new FilterController(this.tableInstance, {});

        const watchlistController = this.optionsWatchlist;

        return (

            <>
                <OptionsWatchListDrawer watchlist={this.state.options_watchlist} updates={this.state.options_watchlist_updates} toggleFn={() => this.toggleOptionsWatchlistToggle()} optionsWatchlist={this.optionsWatchlist} />
                <Modal
                    open={this.state.open}
                    onClose={this.onCloseModal}
                    classNames={{
                        overlay: "customOverlay",
                        modal: "customModal",
                    }}
                >
                    <div className="newsEarningBody newsModal">
                        <div className="trading">
                            <AdvancedRealTimeChart
                                height={500}
                                width="120%"
                                locale="en"
                                theme={"dark"}
                                symbol={this.state.modalTicker}
                            />
                        </div>
                        <SubNewsFeed
                            auth={this.props.auth}
                            ticker={this.state.modalTicker}
                        />
                    </div>
                </Modal>
                <div className="homepage" ref={this.tableHtmlRef}>
                    {window.innerWidth > 1100 ? (
                        <div className="optionsStats">
                            {this.state.sentiment[2] === "a" ? (
                                <div className="table-sentiment-block-overall">
                                    <div className="table-sentiment-block-header put-sentiment">
                                        Sentiment
                                    </div>
                                    <div className="table-sentiment-block-content">
                                        {this.state.sentiment}
                                    </div>
                                    <div className="table-sentiment-percent">
                                        <div className="circle">
                                            <p className="percentText">
                                                {Math.round(
                                                    (this.state.CaP[1] /
                                                        (this.state.CaP[0] +
                                                            this.state
                                                                .CaP[1])) *
                                                    100
                                                ) + "%"}
                                            </p>
                                            <div
                                                className="percent put-sentiment"
                                                style={{
                                                    height:
                                                        Math.round(
                                                            (this.state.CaP[1] /
                                                                (this.state
                                                                    .CaP[0] +
                                                                    this.state
                                                                        .CaP[1])) *
                                                            100
                                                        ) + "%",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            ) : this.state.sentiment[2] === "l" ? (
                                <div className="table-sentiment-block-overall">
                                    <div className="table-sentiment-block-header call-sentiment">
                                        Sentiment
                                    </div>
                                    <div className="table-sentiment-block-content">
                                        {this.state.sentiment}
                                    </div>
                                    <div className="table-sentiment-percent">
                                        <div className="circle">
                                            <p className="percentText">
                                                {Math.round(
                                                    (this.state.CaP[0] /
                                                        (this.state.CaP[0] +
                                                            this.state
                                                                .CaP[1])) *
                                                    100
                                                ) + "%"}
                                            </p>
                                            <div
                                                className="percent call-sentiment"
                                                style={{
                                                    height:
                                                        Math.round(
                                                            (this.state.CaP[0] /
                                                                (this.state
                                                                    .CaP[0] +
                                                                    this.state
                                                                        .CaP[1])) *
                                                            100
                                                        ) + "%",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="table-sentiment-block-overall">
                                    <div className="table-sentiment-block-header overall-sentiment">
                                        Sentiment
                                    </div>
                                    <div className="table-sentiment-block-content">
                                        {this.state.sentiment}
                                    </div>
                                    <div className="table-sentiment-percent">
                                        <div className="circle">
                                            <p className="percentText">
                                                {this.state.CaP[0] >
                                                    this.state.CaP[1]
                                                    ? Math.round(
                                                        (this.state.CaP[0] /
                                                            (this.state
                                                                .CaP[0] +
                                                                this.state
                                                                    .CaP[1])) *
                                                        100
                                                    ) + "%"
                                                    : Math.round(
                                                        (this.state.CaP[1] /
                                                            (this.state
                                                                .CaP[0] +
                                                                this.state
                                                                    .CaP[1])) *
                                                        100
                                                    ) + "%"}
                                            </p>
                                            <div
                                                className="percent overall-sentiment"
                                                style={{
                                                    height:
                                                        this.state.CaP[0] >
                                                            this.state.CaP[1]
                                                            ? Math.round(
                                                                (this.state
                                                                    .CaP[0] /
                                                                    (this
                                                                        .state
                                                                        .CaP[0] +
                                                                        this
                                                                            .state
                                                                            .CaP[1])) *
                                                                100
                                                            ) + "%"
                                                            : Math.round(
                                                                (this.state
                                                                    .CaP[1] /
                                                                    (this
                                                                        .state
                                                                        .CaP[0] +
                                                                        this
                                                                            .state
                                                                            .CaP[1])) *
                                                                100
                                                            ) + "%",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="table-sentiment-block-flows">
                                <div className="table-sentiment-flow">
                                    <div className="table-sentiment-block-header call-sentiment">
                                        Call Flow
                                    </div>
                                    <div className="table-sentiment-flow-content">
                                        {this.state.callFlow}
                                    </div>
                                </div>

                                <div className="sentimentSpace"></div>

                                <div className="table-sentiment-flow">
                                    <div className="table-sentiment-block-header put-sentiment">
                                        Put Flow
                                    </div>
                                    <div className="table-sentiment-flow-content">
                                        {this.state.putFlow}
                                    </div>
                                </div>
                            </div>

                            <div className="verticalLine"></div>

                            <div className="topTicker">
                                <div>
                                    {
                                        // eslint-disable-next-line
                                        this.state.dataTop.map((data) => {
                                            if (data.CALL >= data.PUT + 3) {
                                                return (
                                                    <button
                                                        className="calls"
                                                        onClick={(e) => {
                                                            if (
                                                                this.state
                                                                    .watchlistStatus ===
                                                                "Off"
                                                            )
                                                                this.getWatchlistData(
                                                                    e
                                                                );
                                                            this.addToWatchList(
                                                                data.TICKER
                                                            );
                                                        }}
                                                    >
                                                        {data.TICKER} |{" "}
                                                        {data.CALL}/{data.PUT}
                                                    </button>
                                                );
                                            }
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        // eslint-disable-next-line
                                        this.state.dataTop.map((data) => {
                                            if (data.PUT >= data.CALL + 3) {
                                                return (
                                                    <button
                                                        className="puts"
                                                        onClick={(e) => {
                                                            if (
                                                                this.state
                                                                    .watchlistStatus ===
                                                                "Off"
                                                            )
                                                                this.getWatchlistData(
                                                                    e
                                                                );
                                                            this.addToWatchList(
                                                                data.TICKER
                                                            );
                                                        }}
                                                    >
                                                        {data.TICKER} |{" "}
                                                        {data.CALL}/{data.PUT}
                                                    </button>
                                                );
                                            }
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        // eslint-disable-next-line
                                        this.state.dataTop.map((data) => {
                                            if (
                                                !(data.CALL >= data.PUT + 3) &&
                                                !(data.PUT >= data.CALL + 3)
                                            ) {
                                                return (
                                                    <button
                                                        className="neutral"
                                                        onClick={(e) => {
                                                            if (
                                                                this.state
                                                                    .watchlistStatus ===
                                                                "Off"
                                                            )
                                                                this.getWatchlistData(
                                                                    e
                                                                );
                                                            this.addToWatchList(
                                                                data.TICKER
                                                            );
                                                        }}
                                                    >
                                                        {data.TICKER} |{" "}
                                                        {data.CALL}/{data.PUT}
                                                    </button>
                                                );
                                            }
                                        })
                                    }
                                </div>
                                {/* {this.state.dataTop.map((data) => (
                <button
                  className={`${data.CALL + 3 > data.PUT ? "" : "puts"}
                    ${data.PUT + 3 > data.CALL ? "" : "calls"}
                    ${
                      data.PUT + 4 < data.CALL && data.Call + 4 < data.PUT
                        ? ""
                        : "neutral"
                    }
                  `}
                  onClick={(e) => {
                    if (this.state.watchlistStatus === "Off")
                      this.getWatchlistData(e);
                    this.addToWatchList(data.TICKER);
                  }}
                >
                  {data.TICKER} | {data.CALL}/{data.PUT}
                </button>
              ))} */}
                            </div>
                        </div>
                    ) : (
                        <div className="optionsStats">
                            <div className="flowRow">
                                <div className="flowMobile">
                                    {this.state.sentiment[2] === "a" ? (
                                        <div className="table-sentiment-block-overall">
                                            <div className="table-sentiment-block-header put-sentiment">
                                                Sentiment
                                            </div>
                                            <div className="table-sentiment-block-content">
                                                {this.state.sentiment}
                                            </div>
                                            <div className="table-sentiment-percent">
                                                <div className="circle">
                                                    <p className="percentText">
                                                        {Math.round(
                                                            (this.state.CaP[1] /
                                                                (this.state
                                                                    .CaP[0] +
                                                                    this.state
                                                                        .CaP[1])) *
                                                            100
                                                        ) + "%"}
                                                    </p>
                                                    <div
                                                        className="percent put-sentiment"
                                                        style={{
                                                            height:
                                                                Math.round(
                                                                    (this.state
                                                                        .CaP[1] /
                                                                        (this
                                                                            .state
                                                                            .CaP[0] +
                                                                            this
                                                                                .state
                                                                                .CaP[1])) *
                                                                    100
                                                                ) + "%",
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : this.state.sentiment[2] === "l" ? (
                                        <div className="table-sentiment-block-overall">
                                            <div className="table-sentiment-block-header call-sentiment">
                                                Sentiment
                                            </div>
                                            <div className="table-sentiment-block-content">
                                                {this.state.sentiment}
                                            </div>
                                            <div className="table-sentiment-percent">
                                                <div className="circle">
                                                    <p className="percentText">
                                                        {Math.round(
                                                            (this.state.CaP[0] /
                                                                (this.state
                                                                    .CaP[0] +
                                                                    this.state
                                                                        .CaP[1])) *
                                                            100
                                                        ) + "%"}
                                                    </p>
                                                    <div
                                                        className="percent call-sentiment"
                                                        style={{
                                                            height:
                                                                Math.round(
                                                                    (this.state
                                                                        .CaP[0] /
                                                                        (this
                                                                            .state
                                                                            .CaP[0] +
                                                                            this
                                                                                .state
                                                                                .CaP[1])) *
                                                                    100
                                                                ) + "%",
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="table-sentiment-block-overall">
                                            <div className="table-sentiment-block-header overall-sentiment">
                                                Sentiment
                                            </div>
                                            <div className="table-sentiment-block-content">
                                                {this.state.sentiment}
                                            </div>
                                            <div className="table-sentiment-percent">
                                                <div className="circle">
                                                    <p className="percentText">
                                                        {this.state.CaP[0] >
                                                            this.state.CaP[1]
                                                            ? Math.round(
                                                                (this.state
                                                                    .CaP[0] /
                                                                    (this
                                                                        .state
                                                                        .CaP[0] +
                                                                        this
                                                                            .state
                                                                            .CaP[1])) *
                                                                100
                                                            ) + "%"
                                                            : Math.round(
                                                                (this.state
                                                                    .CaP[1] /
                                                                    (this
                                                                        .state
                                                                        .CaP[0] +
                                                                        this
                                                                            .state
                                                                            .CaP[1])) *
                                                                100
                                                            ) + "%"}
                                                    </p>
                                                    <div
                                                        className="percent overall-sentiment"
                                                        style={{
                                                            height:
                                                                this.state
                                                                    .CaP[0] >
                                                                    this.state
                                                                        .CaP[1]
                                                                    ? Math.round(
                                                                        (this
                                                                            .state
                                                                            .CaP[0] /
                                                                            (this
                                                                                .state
                                                                                .CaP[0] +
                                                                                this
                                                                                    .state
                                                                                    .CaP[1])) *
                                                                        100
                                                                    ) + "%"
                                                                    : Math.round(
                                                                        (this
                                                                            .state
                                                                            .CaP[1] /
                                                                            (this
                                                                                .state
                                                                                .CaP[0] +
                                                                                this
                                                                                    .state
                                                                                    .CaP[1])) *
                                                                        100
                                                                    ) + "%",
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="table-sentiment-block-flows">
                                        <div className="table-sentiment-flow">
                                            <div className="table-sentiment-block-header call-sentiment">
                                                Call Flow
                                            </div>
                                            <div className="table-sentiment-flow-content">
                                                {this.state.callFlow}
                                            </div>
                                        </div>

                                        <div className="sentimentSpace"></div>

                                        <div className="table-sentiment-flow">
                                            <div className="table-sentiment-block-header put-sentiment">
                                                Put Flow
                                            </div>
                                            <div className="table-sentiment-flow-content">
                                                {this.state.putFlow}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="topTickerMobile">
                                    {
                                        // eslint-disable-next-line
                                        this.state.dataTop.map((data) => {
                                            if (data.CALL >= data.PUT + 3) {
                                                return (
                                                    <button
                                                        className="calls"
                                                        onClick={(e) => {
                                                            if (
                                                                this.state
                                                                    .watchlistStatus ===
                                                                "Off"
                                                            )
                                                                this.getWatchlistData(
                                                                    e
                                                                );
                                                            this.addToWatchList(
                                                                data.TICKER
                                                            );
                                                        }}
                                                    >
                                                        {data.TICKER} |{" "}
                                                        {data.CALL}/{data.PUT}
                                                    </button>
                                                );
                                            }
                                        })
                                    }
                                </div>
                            </div>

                            <div className="flowRow">
                                <div className="topTickerMobile">
                                    {
                                        // eslint-disable-next-line
                                        this.state.dataTop.map((data) => {
                                            if (data.PUT >= data.CALL + 3) {
                                                return (
                                                    <button
                                                        className="puts"
                                                        onClick={(e) => {
                                                            if (
                                                                this.state
                                                                    .watchlistStatus ===
                                                                "Off"
                                                            )
                                                                this.getWatchlistData(
                                                                    e
                                                                );
                                                            this.addToWatchList(
                                                                data.TICKER
                                                            );
                                                        }}
                                                    >
                                                        {data.TICKER} |{" "}
                                                        {data.CALL}/{data.PUT}
                                                    </button>
                                                );
                                            }
                                        })
                                    }
                                </div>
                                <div className="topTickerMobile">
                                    {
                                        // eslint-disable-next-line
                                        this.state.dataTop.map((data) => {
                                            if (
                                                !(data.CALL >= data.PUT + 3) &&
                                                !(data.PUT >= data.CALL + 3)
                                            ) {
                                                return (
                                                    <button
                                                        className="neutral"
                                                        onClick={(e) => {
                                                            if (
                                                                this.state
                                                                    .watchlistStatus ===
                                                                "Off"
                                                            )
                                                                this.getWatchlistData(
                                                                    e
                                                                );
                                                            this.addToWatchList(
                                                                data.TICKER
                                                            );
                                                        }}
                                                    >
                                                        {data.TICKER} |{" "}
                                                        {data.CALL}/{data.PUT}
                                                    </button>
                                                );
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )}


                    <div style={{ display: "flex", flexFlow: "row", gap: "16px", marginLeft: "60px", marginTop: "24px", overflowX: "auto", scrollbarColor: "transparent" }}>
                        {this.state.events.map((eventNotif, index) =>
                            <EventNotification event={eventNotif} onDismiss={() => this.dismissEventNotification(index)} />
                        )}
                    </div>

                    <div className="tableWatchlistContainer">

                        <div className={stockOptionsTable}>
                            {this.state.loadingStatus === "loaded" || this.state.data.length ?
                                <Table
                                    data={this.state.data}
                                    ref={this.tableInstance}
                                    optionsWatchlist={watchlistController}
                                    columns={tableColumns}
                                    onOpenModal={this.onOpenModal}
                                    addOptionToWatchlistFn={(row) => this.addRowToOptionsWatchlist(row)}
                                    onCloseModal={this.onCloseModal}
                                    hidden={this.state.hidden}
                                /> :
                                <div style={{ color: "white", width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {this.state.loadingStatus === "errored" ?
                                        <h4>Sorry for the inconvinience, there was an error processing your request.</h4>
                                        :
                                        <div style={{ display: "flex", justifyContent: "center", flexFlow: "column" }}>
                                            {this.state.timeEstimate.percentage && this.state.loadingStatus !== "NO DATA FOUND"
                                                ?
                                                <CircularProgressWithLabel value={this.state.timeEstimate.percentage} variant="determinate" style={{ color: "goldenrod" }} color="secondary" />
                                                :
                                                <CircularProgress variant="indeterminate" style={{ color: "goldenrod", alignSelf: "center" }} size="5rem" />
                                            }
                                            <h2 style={{ color: "white", marginTop: "36px" }}>{this.state.loadingStatus}</h2>
                                            {this.state.timeEstimate.estimate > 30 && <h4 style={{ width: "400px" }}>TIP: You have requested a very large amount of data which will take longer than 30 seconds to process and tabulate, please select the ticker you are interested in, price, shorter range of dates or other filters to get your data much faster. </h4>}
                                        </div>}
                                </div>
                            }
                            <div>
                                <Button variant="contained" onClick={() => this.downloadExcel()} style={{ fontSize: "12px", marginLeft: "85%" }}>Download as Excel</Button>
                            </div>
                        </div>

                        {this.state.watchlistOpen &&
                            !(window.innerWidth > 1100) ? (
                            <div
                                onClick={this.watchlistOffClick}
                                className="watchlistToggleBackground"
                            ></div>
                        ) : null}

                        <div className={watchlistSidebar}>
                            {window.outerWidth > 1100 ? (
                                <div
                                    className={
                                        watchlistToggle +
                                        " " +
                                        watchlistFocus +
                                        " " +
                                        toggleGlow
                                    }
                                >
                                    <button onClick={this.watchlistToggle}>
                                        {!this.state.watchlistOpen ? (
                                            <>
                                                <p class="rotated top">
                                                    {" "}
                                                    WATCHLIST/FILTERS{" "}
                                                </p>
                                                <img
                                                    src={WatchlistToggle}
                                                    alt="watchlist_toggle"
                                                    className={
                                                        this.state.pulse &&
                                                            !this.state
                                                                .watchlistOpen
                                                            ? "watchlistPulseOn watchlistSymbol"
                                                            : "watchlistPulseOff watchlistSymbol"
                                                    }
                                                />
                                                <p class="rotated">
                                                    {" "}
                                                    WATCHLIST/FILTERS{" "}
                                                </p>
                                            </>
                                        ) : (
                                            <img
                                                src={WatchlistToggle}
                                                alt="watchlist_toggle"
                                                className={
                                                    this.state.pulse &&
                                                        !this.state.watchlistOpen
                                                        ? "watchlistPulseOn"
                                                        : "watchlistPulseOff"
                                                }
                                            />
                                        )}
                                    </button>
                                </div>
                            ) : (
                                <div
                                    className={
                                        watchlistToggle +
                                        " " +
                                        watchlistFocus +
                                        " " +
                                        toggleGlow
                                    }
                                >
                                    <button
                                        class="toggleButton"
                                        onClick={this.watchlistToggle}
                                    >
                                        {this.state.watchlistOpen ? (
                                            <span class="textArrow">
                                                {" "}
                                                &#8614;{" "}
                                            </span>
                                        ) : (
                                            <span class="textArrow">
                                                {" "}
                                                &#8612;
                                            </span>
                                        )}
                                    </button>
                                </div>
                            )}
                            <div className="watchlistHeader">
                                <div className="watchlistTabs">
                                    <div
                                        className={
                                            this.state.watchlistTab
                                                ? "watchlistTabText watchlistTabOpen"
                                                : "watchlistTabText"
                                        }
                                        onClick={() =>
                                            this.setState({
                                                watchlistTab: true,
                                            })
                                        }
                                    >
                                        <p>Filter</p>
                                    </div>
                                    <div
                                        className={
                                            !this.state.watchlistTab
                                                ? "watchlistTabText watchlistTabOpen"
                                                : "watchlistTabText"
                                        }
                                        onClick={() =>
                                            this.setState({
                                                watchlistTab: false,
                                            })
                                        }
                                    >
                                        <p>Watchlist</p>
                                    </div>
                                </div>
                            </div>
                            <div className="watchlistBodyContainer">

                                <div className="watchlistBody">
                                    {this.state.watchlistTab ? (
                                        <>
                                            <div className="watchlistTabHeader">
                                                Filter
                                            </div>

                                            <div className="speech">
                                                <p className="speechText">
                                                    GOLDEN SWEEPS CALLOUT
                                                </p>
                                                <label className="switchSpeech">
                                                    <input
                                                        type="checkbox"
                                                        onClick={() =>
                                                            this.setState({
                                                                calloutToggle: !this
                                                                    .state
                                                                    .calloutToggle,
                                                            })
                                                        }
                                                        checked={
                                                            this.state
                                                                .calloutToggle ===
                                                            true
                                                        }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                            <div className="speech">
                                                <p className="speechText">
                                                    🚩 ONLY
                                                </p>

                                                <label className="switchSpeech">
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            const toggle = !this
                                                                .state
                                                                .redFlagToggle;
                                                            this.setState({
                                                                redFlagToggle: toggle,
                                                            })
                                                            const value = toggle || undefined
                                                            this.tableInstance.current.setFilter("sentiment_flipped", value)
                                                        }}
                                                        checked={
                                                            this.state
                                                                .redFlagToggle ===
                                                            true
                                                        }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                            <div className="speech">
                                                <p className="speechText">
                                                    NON-EXPIRED ONLY
                                                </p>

                                                <label className="switchSpeech">
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            const toggle = !this
                                                                .state
                                                                .nonExpiredToggle;
                                                            this.setState({
                                                                nonExpiredToggle: toggle,
                                                            })
                                                            let value = undefined;
                                                            if (toggle) {
                                                                const date = this.getLatestTradingDay();
                                                                const dateStr = moment(date).format("MM/DD/YY")
                                                                value = [dateStr, undefined]
                                                            }
                                                            this.tableInstance.current.setFilter("date_expiration", value)
                                                        }}
                                                        checked={
                                                            this.state
                                                                .nonExpiredToggle
                                                        }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                            <div className="speech">
                                                <p className="speechText">
                                                    UPCOMING EARNINGS ONLY
                                                </p>

                                                <label className="switchSpeech">
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            const toggle = !this
                                                                .state
                                                                .upcomingEarningsToggle;
                                                            this.setState({
                                                                upcomingEarningsToggle: toggle,
                                                            })
                                                            const value = toggle ? 1 : undefined;
                                                            this.tableInstance.current.setFilter("earnings_date", value)
                                                        }}
                                                        checked={
                                                            this.state
                                                                .upcomingEarningsToggle
                                                        }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                            <div className="speech">
                                                <p className="speechText">
                                                    ADV. DATE FILTERING
                                                </p>

                                                <label className="switchSpeech">
                                                    <input
                                                        type="checkbox"
                                                        onClick={() => {
                                                            this.toggleDateFilter();
                                                        }}
                                                        checked={
                                                            this.state
                                                                .dateFilterToggle ===
                                                            true
                                                        }
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>

                                            <div className="filterTabElement">
                                                <p>Tickers:</p>
                                                <input
                                                    type="text"
                                                    value={this.state.quick}
                                                    onChange={(e) => {
                                                        this.quickFilter(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <MinMaxFilter
                                                column="price"
                                                filterController={filterController}
                                                label={"Price"}
                                                step={1000}
                                                onChange={(min, max) => this.addMinMaxFilter('price', min, max)}
                                            />
                                            <MinMaxFilter
                                                column="cost_basis"
                                                filterController={filterController}
                                                label={"Cost"}
                                                onChange={(min, max) => this.addMinMaxFilter('cost_basis', min, max)}
                                                step={1000}
                                            />
                                            <MinMaxFilter
                                                column="aggressor_ind"
                                                onChange={(min, max) => this.addMinMaxFilter('aggressor_ind', min, max)}
                                                max={100}
                                                step={1}
                                                filterController={filterController}
                                                label={"AI %"}
                                            />
                                            <MinMaxFilter
                                                column="percent_move"
                                                onChange={(min, max) => this.addMinMaxFilter('percent_move', min, max)}
                                                step={1}
                                                filterController={filterController}
                                                label={"Move %"}
                                            />
                                            {this.state.dateFilterToggle ?
                                                <div>
                                                    <div className="filterTabElement">
                                                        <p>Start Date:</p>
                                                        <DatePicker
                                                            selected={this.state.filters['minDate']}
                                                            onChange={(date) =>
                                                                this.handleFilterChange("minDate", date)
                                                            }
                                                            filterDate={this.isWeekday}
                                                            minDate={new Date(2021, 0, 1)}
                                                            maxDate={this.state.filters['maxDate'] || this.getLatestTradingDay()}
                                                        />
                                                    </div>
                                                    <div className="filterTabElement">
                                                        <p>End Date:</p>
                                                        <DatePicker
                                                            selected={this.state.filters['maxDate']}
                                                            onChange={(date) =>
                                                                this.handleFilterChange("maxDate", date)
                                                            }
                                                            filterDate={this.isWeekday}
                                                            minDate={new Date(2021, 0, 1)}
                                                            maxDate={this.getLatestTradingDay()} />
                                                        <div />
                                                    </div>
                                                    {this.state.dateFilterToggle &&
                                                        <Button style={{ background: "goldenrod", color: "black", width: "150px", marginTop: "24px", marginLeft: "28px" }} onClick={() => this.resetAndGetData()}>SEARCH</Button>}
                                                </div>
                                                :
                                                <div className="filterTabElement">
                                                    <p>Date:</p>
                                                    <DatePicker
                                                        selected={this.state.filters['eqDate'] || this.getLatestTradingDay()}
                                                        onChange={(date) =>
                                                            this.handleFilterChange("eqDate", date)
                                                        }
                                                        filterDate={this.isWeekday}
                                                        minDate={new Date(2021, 0, 1)}
                                                        maxDate={Date.now()}
                                                    />
                                                </div>
                                            }

                                            <div className="filterTabElement filterTabFirstBox">
                                                <div className="filterTabElement filterTabFirstBox">
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="allActivity"
                                                            name="chanfilter"
                                                            checked={this.state.all}
                                                            onClick={() => {
                                                                this.tableInstance.current.setFilter(
                                                                    "chan_filter",
                                                                    undefined
                                                                );
                                                                this.setState({
                                                                    all: true,
                                                                });
                                                            }}
                                                        />
                                                        <label for="allActivity">
                                                            All Activity
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="sellToOpen"
                                                            name="chanfilter"
                                                            onClick={() => {
                                                                this.tableInstance.current.setFilter(
                                                                    "chan_filter",
                                                                    "SILVER"
                                                                );
                                                                this.setState({
                                                                    all: false,
                                                                });
                                                            }}
                                                        />
                                                        <label for="sellToOpen">
                                                            Potential Sell-To-Open
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="zeroDay"
                                                            name="chanfilter"
                                                            onClick={() => {
                                                                this.tableInstance.current.setFilter(
                                                                    "chan_filter",
                                                                    "ROSE"
                                                                );
                                                                this.setState({
                                                                    all: false,
                                                                });
                                                            }}
                                                        />
                                                        <label for="zeroDay">
                                                            Zero Day
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id="golden"
                                                            name="chanfilter"
                                                            onClick={() => {
                                                                this.tableInstance.current.setFilter(
                                                                    "chan_filter",
                                                                    "GOLDEN"
                                                                );
                                                                this.setState({
                                                                    all: false,
                                                                });
                                                            }}
                                                        />
                                                        <label for="golden">
                                                            GOLDEN SWEEPS
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="watchlistTabHeader">
                                                Watchlist
                                            </div>

                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    onClick={this.getWatchlistData}
                                                    checked={
                                                        this.state
                                                            .watchlistStatus ===
                                                            "On"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <span className="slider round"></span>
                                            </label>

                                            <div className="watchlistTabTickerEdit">
                                                <p className="watchlistTabTickerEditHeader">
                                                    Edit Tickers
                                                </p>

                                                <div className="watchlistTabAdd">
                                                    <p>Add:</p>
                                                    <input
                                                        className="addBar"
                                                        type="text"
                                                        onKeyPress={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.addToWatchList(
                                                                    e.target.value
                                                                );
                                                                e.target.value = "";
                                                            }
                                                        }}
                                                    ></input>
                                                </div>

                                                <div className="watchlistTabRemove">
                                                    <p>Remove:</p>
                                                    <input
                                                        className="removeBar"
                                                        type="text"
                                                        onKeyPress={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.removeFromWatchList(
                                                                    e.target.value
                                                                );
                                                                e.target.value = "";
                                                            }
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>

                                            <div className="watchlistTabWatchedTickers">
                                                <p className="watchlistTabWatchedTickersHeader">
                                                    Watched Tickers
                                                </p>

                                                <div className="watchlistTabTickerBox">
                                                    {this.state.watchlist.map(
                                                        populateCurrentTickers,
                                                        this
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default OptionsTable;
