import React, { Component } from "react";
import Accordian from "./components/Accordian";
import FAQList from "./assets/faq.json";
class HowTo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            error: "",
            status: "",
        };
    }

    render() {
        const { isAuthenticated } = this.props.auth;
        if (isAuthenticated() && this.state.profile !== null) {
            this.props.auth.getProfile((profile, error) =>
                this.setState({ profile, error })
            );
        }
        // if (!profile) return null;
        // setTimeout(function() {
        //   history.push("/");
        //  }, 10000);

        return (
            <>
                <br></br>
                <div className={"home-table"}>
                    <div className={"home-column1 video"}>
                        <div className="center vid-centered">
                            <iframe
                                width="85%"
                                height="500"
                                src="https://www.youtube.com/embed/racIkT0YKKw"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                    <div className={"home-column2"}>
                        <div className="center">
                            <div className="section-subtitle">
                                {" "}
                                Using the Platform{" "}
                            </div>
                        </div>
                        <div className={"definition"}>
                            This platform was made BY traders FOR traders. The
                            platform is designed to be intuitive, and enable
                            traders to make important decisions while filtering
                            out the clutter.
                        </div>
                        <div className="center">
                            <div className="section-subtitle">
                                <br /> Frequently Asked Questions{" "}
                            </div>
                        </div>
                        <Accordian itemList={FAQList} />
                    </div>
                </div>
            </>
        );
    }
}
export default HowTo;
