import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "white",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      iconColor: "white",
      fontSize: "16px",
      "::placeholder": {
        color: "white",
      },
    },
    invalid: {
      color: "white",
      iconColor: "white",
    },
  },
};
const mystyle = {
  color: "white",
  paddingLeft: "5%",
  paddingRight: "5%",
  paddingTop: "2%",
  paddingBottom: "2%",
  fontFamily: "Arial",
  border: "2px solid black",
};

export default function CardInput() {
  return (
    <div style={mystyle}>
      <CardElement options={CARD_ELEMENT_OPTIONS}></CardElement>
    </div>
  );
}
