import React from "react";
import { NavLink } from "react-router-dom";
import NameAndSlogan from "./images/nameAndSlogan.png";
export default function Hamburger(props) {
    const { isAuthenticated, login, logout } = props.auth;
    return (
        <>
            <NavLink activeClassName="selected" width="400" exact to="/">
                <img
                    src={NameAndSlogan}
                    alt="CallsOrPuts"
                    className="center"
                    width="400"
                />
            </NavLink>
            <ul id="examples">
                {isAuthenticated() ? (
                    <div className="example center">
                        <li className="example nav-border-right">
                            <NavLink
                                activeClassName="selected"
                                className="hover hover-3"
                                exact
                                to="/Options"
                            >
                                Options Sweeps
                            </NavLink>
                        </li>
                        <li className="example nav-border-right">
                            <NavLink
                                activeClassName="selected"
                                className="hover hover-3"
                                exact
                                to="/news"
                            >
                                News and Earnings
                            </NavLink>
                        </li>
                        <li className="example nav-border-right">
                            <NavLink
                                activeClassName="selected"
                                className="hover hover-3"
                                exact
                                to="/HowTo"
                            >
                                How To
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                activeClassName="selected"
                                className="example nav-border-right"
                                to={{
                                    pathname: "https://www.chancetrades.com/",
                                }}
                                target="_blank"
                            >
                                Trading Alerts
                            </NavLink>
                        </li>
                        <li className="example nav-border-right">
                            <NavLink exact to="/Profile">
                                Account Settings
                            </NavLink>
                        </li>
                        <li className="example">
                            <button className="hover hover-3" onClick={logout}>
                                Logout
                            </button>
                        </li>
                    </div>
                ) : (
                    <div className="example center">
                        <li className="example nav-border-right">
                            <button className="hover hover-4" onClick={login}>
                                Login
                            </button>
                        </li>
                        <li className="example nav-border-right">
                            <button className="hover hover-3" onClick={login}>
                                Subscribe Now
                            </button>
                        </li>
                        <li className="example nav-border-right">
                            <NavLink
                                activeClassName="selected"
                                className="hover hover-3"
                                to={{
                                    pathname: "https://www.chancetrades.com/",
                                }}
                                target="_blank"
                            >
                                Trading Alerts
                            </NavLink>
                        </li>
                        <li className="example">
                            <NavLink
                                activeClassName="selected"
                                className="hover hover-3"
                                exact
                                to="/HowTo"
                            >
                                How-To-Use
                            </NavLink>
                        </li>
                    </div>
                )}
            </ul>
        </>
    );
}
