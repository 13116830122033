import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Accordian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemList: this.props.itemList,
        };
    }
    render() {
        const accordianElements = this.state.itemList.map((accordianEntry) => {
            return (
                <AccordionItem className={"accordianItem"}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {accordianEntry.header}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p className={"definition"}>{accordianEntry.body}</p>
                    </AccordionItemPanel>
                </AccordionItem>
            );
        });
        return (
            <>
                <Accordion
                    className={"accordianBorder"}
                    allowMultipleExpanded="true"
                    allowZeroExpanded="true"
                >
                    {accordianElements}
                </Accordion>
            </>
        );
    }
}

export default Accordian;
