import React, { Component } from "react";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { axiosClient } from "./BASE_URL"
class SubNewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            error: "",
            status: "",
            subArticles: [],
            historical: false,
            date: new Date(),
            page: 0,
            topic: "",
            ticker: this.props.ticker,
            popUp: false,
            clearable: "",
            earnings: [],
            earnings_url: "",
        };
    }

    componentDidMount() {
        this.loadUserProfile();
        this.rendersubArticles();
        this.getEarnings();
        setInterval(() => {
            this.loadUserProfile();
            this.rendersubArticles();
        }, 60000);
    }

    loadUserProfile() {
        if (this.props.auth.isAuthenticated()) {
            this.props.auth.getProfile((profile, error) =>
                this.setState({ profile, error })
            );
        } else {
            this.props.auth.login();
        }
    }
    getEarnings = async () => {
        try {
            this.setState({
                earnings_dates: [],
                earnings_url: "",
            });
            const earnings_payload = {
                id: this.props.auth.userProfile.sub,
                ticker: this.state.ticker,
            };
            const res = await axiosClient.post(
                "/company-earnings",
                earnings_payload
            );
            if (res.data.earnings?.length > 0) {
                this.setState({
                    earnings: res.data.earnings,
                    earnings_url: res.data.url,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };
    rendersubArticles = async () => {
        try {
            const news_payload = {
                id: this.props.auth.userProfile.sub,
                //dateFrom:(moment(new Date(2021, 0, 1)).tz("America/Toronto").format("YYYY-MM-DD")), //
                //dateTo:(moment(this.date).tz("America/Toronto").format("YYYY-MM-DD")),
                ticker: this.state.ticker,
                topics: this.state.topic,
                page: this.state.page,
            };
            if (
                this.state.date.getFullYear() !== new Date().getFullYear() ||
                this.state.date.getMonth() !== new Date().getMonth() ||
                this.state.date.getDate() !== new Date().getDate()
            ) {
                news_payload["dateFrom"] = moment(this.state.date)
                    .tz("America/Toronto")
                    .format("YYYY-MM-DD");
                //(moment(new Date(2021, 0, 1)).tz("America/Toronto").format("YYYY-MM-DD"));
                news_payload["dateTo"] = moment(new Date())
                    .tz("America/Toronto")
                    .format("YYYY-MM-DD");
                //(moment(this.date).tz("America/Toronto").format("YYYY-MM-DD"));
            }
            const res = await axiosClient.post("/news", news_payload);
            const subArticles = res.data.news;

            this.setState({
                subArticles: subArticles,
            });
        } catch (err) {
            console.log(err);
        }
    };

    changeRoute = () => {
        if (this.props.auth.isAuthenticated()) {
            this.props.history.push("profile");
        } else this.props.auth.login();
    };

    isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    buttonInput = ({ value, onClick }) => (
        <button className="newsDatePickerButton" onClick={onClick}>
            {value}
        </button>
    );

    pageDown = () => {
        if (this.state.page > 0)
            this.setState(
                { page: this.state.page - 1 },
                this.rendersubArticles
            );
    };

    pageUp = () => {
        if (true)
            this.setState(
                { page: this.state.page + 1 },
                this.rendersubArticles
            );
    };

    topicFilter = (e) => {
        this.setState({ topic: e.toString() }, this.rendersubArticles);
    };

    tickerFilter = (e) => {
        this.setState(
            { ticker: e.toString().toUpperCase() },
            this.rendersubArticles
        );
    };

    togglePopUp = () => {
        this.setState({ popUp: !this.state.popUp }, this.rendersubArticles);
    };
    clear = () => {
        document.getElementById("tickerInput").value = "";
        this.setState({ ticker: "" }, this.rendersubArticles);
    };

    render() {
        const subArticle = this.state.subArticles.map((a) => {
            // eslint-disable-next-line
            {
                var subArticleBody;

                if (a.image.length > 0) {
                    subArticleBody = "subArticleBody";
                } else {
                    subArticleBody = "subArticleBodyNoImg";
                }
                if (a.stocks.length > 8) {
                    return (
                        <div
                            className="subNewsArticle"
                            onClick={() => window.open(a.url)}
                        >
                            {a.image.length > 0 && (
                                <img
                                    className="subArticleImage"
                                    src={a.image[0].url}
                                    alt={a.image.url}
                                ></img>
                            )}
                            {/* {(!a.image.length > 0) &&
                            <img className="subArticleImage" alt={a.image.url}></img>
                        } */}
                            <div className={subArticleBody}>
                                <h4 className="subArticleTitle subArticleElement">
                                    {a.title}
                                </h4>
                                <p className="subArticleDescription subArticleElement">
                                    {a.teaser}
                                </p>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="subNewsArticle"
                            onClick={() => window.open(a.url)}
                        >
                            {a.image.length > 0 && (
                                <img
                                    className="subArticleImage"
                                    src={a.image[0].url}
                                    alt={a.image.url}
                                ></img>
                            )}
                            {/* {(!a.image.length > 0) &&
                            <img className="subArticleImage" alt={a.image.url}></img>
                        } */}
                            <div className={subArticleBody}>
                                <h4 className="subArticleTitle subArticleElement">
                                    {a.title}
                                </h4>
                                <p className="subArticleDescription subArticleElement">
                                    {a.teaser}
                                </p>
                            </div>
                        </div>
                    );
                }
            }
        });
        let earningsElements = this.state.earnings.map((a) => {
            // eslint-disable-next-line
            {
                return (
                    <div
                        className="subNewsArticle"
                        onClick={() => window.open(this.state.earnings_url)}
                    >
                        <div className="subArticleBodyNoImg">
                            <h4 className="subArticleTitle subArticleElement">
                                {a.period_year}
                                {a.period} - {a.exchange} - {a.date}
                            </h4>
                        </div>
                    </div>
                );
            }
        });
        return (
            <div className="subNewsFeed">
                {this.state.earnings.length > 0 ? (
                    <>
                        <h1> {this.props.ticker} Earnings </h1>
                        <div className="subNewsArticle">{earningsElements}</div>
                    </>
                ) : (
                    <></>
                )}
                <h1> {this.props.ticker} News </h1>
                <div className="subNewsArticle">
                    {subArticle}
                    {/* {console.log(moment(this.date).tz("America/Toronto").format("YYYY-MM-DD"))} */}
                </div>
                <div className="pageSelect">
                    <button className="prevPage" onClick={this.pageDown}>
                        {" "}
                        &laquo; Previous{" "}
                    </button>
                    <p>{this.state.page + 1}</p>
                    <button className="nextPage" onClick={this.pageUp}>
                        {" "}
                        Next &raquo;{" "}
                    </button>
                </div>
            </div>
        );
    }
}
export default SubNewsFeed;
