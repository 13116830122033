import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const CMS_BASE_URL = process.env.REACT_APP_CMS_BASE_URL

export default function Banner({ props }) {
    const [bannerData, setBannerData] = useState({});
    const [bgUrl, setBgUrl] = useState("")
    let location = useLocation();

    async function getCmsData() {
        const CMS_URL = `${CMS_BASE_URL}items/CallsOrPuts_Banner`
        const resp = await axios.get(CMS_URL)
        const data = resp.data.data
        setBannerData(data);
        const bgUrl = `${CMS_BASE_URL}assets/${data.background}`
        console.log({ bgUrl });
        setBgUrl(bgUrl);
    }

    useEffect(() => {
        getCmsData()
    }, [])

    return location.pathname === '/' && bannerData.active ? <div className="banner" style={{ backgroundImage: `url('${bgUrl}')`, display: "flex", color: bannerData.text_color }}>
        <h5 className="bannerText">{bannerData.banner_value}</h5>
    </div> : null
}