import React, { Component } from "react";
import OptionsTable from "./OptionsTable";
import SubscriptionOptions from "./SubscriptionOptions";

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      error: "",
      status: "",
    };
  }

  componentDidMount() {
    this.loadUserProfile();
  }


  loadUserProfile() {
    if (this.props.auth.isAuthenticated()) {
      this.props.auth.getProfile((profile, error) =>
        this.setState({ profile, error })
      );
    } else {
      this.props.auth.login();
    }
  }
  changeRoute = () => {
    if (this.props.auth.isAuthenticated()) {
      this.props.history.push("profile");
    } else this.props.auth.login();
  };
  render() {
    if (this.props.auth.getPaymentStatus()) {
      return (
        <div>
          <OptionsTable auth={this.props.auth} />
          
        </div>
      );
    } else {
      return <SubscriptionOptions auth={this.auth} {...this.props} />;
    }
  }
}
export default Options;
