import { createBrowserHistory } from "history";
import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

class SubscriptionOptions extends Component {

  history = createBrowserHistory();

  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      error: "",
      status: "",
    };
  }

  changeRoute = () => {
    if (this.props.auth.isAuthenticated()) {
      this.history.push("profile");
    } else this.props.auth.login();
  };
  render() {
    const { isAuthenticated, login } = this.props.auth;
    if (isAuthenticated() && this.state.profile !== null) {
      this.props.auth.getProfile((profile, error) =>
        this.setState({ profile, error })
      );
    }
    return (
      <div id="pricing" className="section purple">
        <div className="container-3 w-container">
          <p className="plansTitle">Flexible Plans</p>
          <p className="plansBody">Plans for every trade level</p>
          <div className="w-row" onClick={this.changeRoute}>
            <div className="w-col w-col-4">
              <div
                data-ix="fade-in-on-scroll"
                className="plan-wrapper planElement planSilver"
              >
                <h3 className="planElementTitle">Monthly</h3>
                <div className="planElementPrice">
                  <strong className="price-accent">$65 </strong>
                </div>
                <div className="divider"></div>

                <div className="addl-feature">
                  Real-Time Options Sweeps Data{" "}
                </div>
                <div className="addl-feature">
                  Simple Filters For Complex Data{" "}
                </div>
                <div className="addl-feature">Market Overview</div>
                <div className="addl-feature">Same-Day Customer Support</div>
                <div className="addl-feature">CANCEL ANYTIME</div>
                <div className="div-block-2"></div>
                <div className="div-block-2"></div>
                <div className="div-block-6"></div>
                <div className="div-block-1"></div>
                <div className="subscribe-now">
                  {isAuthenticated() ? (
                    <NavLink
                      className="button in-pricing"
                      exact
                      to="/Profile"
                      data-ix="fade-in-on-load"
                    >
                      Subscribe Now
                    </NavLink>
                  ) : (
                    <Link className="button in-pricing" onClick={login}>
                      Subscribe Now{" "}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="w-col w-col-4">
              <div
                data-ix="fade-in-on-scroll"
                className="plan-wrapper planSilver"
              >
                <h3 className="planElementTitle">Quarterly</h3>
                <div className="planElementPrice">
                  <strong className="price-accent">$175 </strong>
                </div>
                <div className="divider"></div>
                <div className="addl-feature">
                  Real-Time Options Sweeps Data{" "}
                </div>
                <div className="addl-feature">
                  Simple Filters For Complex Data{" "}
                </div>
                <div className="addl-feature">Market Overview</div>
                <div className="addl-feature">Same-Day Customer Support</div>
                <div className="addl-feature">
                  Premium Discord Chat Access / Live Voice Chat (No Alerts)
                </div>
                <div className="addl-feature">
                  10% DISCOUNTED VALUE OFF MONTHLY PLAN
                </div>
                <div className="addl-feature">CANCEL ANYTIME</div>
                <div className="div-block-3 _1"></div>
                <div className="subscribe-now">
                  {isAuthenticated() ? (
                    <NavLink
                      className="button in-pricing"
                      exact
                      to="/Profile"
                      data-ix="fade-in-on-load"
                    >
                      Subscribe Now
                    </NavLink>
                  ) : (
                    <Link className="button in-pricing" onClick={login}>
                      Subscribe Now{" "}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="w-col w-col-4">
              <div
                data-ix="fade-in-on-scroll"
                className="plan-wrapper planElement planGold"
              >
                <h3 className="planElementTitle">Annual</h3>
                <div className="planElementPrice">
                  <strong className="price-accent">$625 </strong>
                </div>
                <div className="divider"></div>

                <div className="addl-feature">
                  Real-Time Options Sweeps Data{" "}
                </div>
                <div className="addl-feature">
                  Simple Filters For Complex Data{" "}
                </div>
                <div className="addl-feature">Market Overview</div>
                <div className="addl-feature">Same-Day Customer Support</div>
                <div className="addl-feature">
                  Premium Discord Chat Access / Live Voice Chat
                </div>
                <div className="addl-feature">
                  Unlimited Discord Alert Service{" "}
                </div>
                <div className="addl-feature">
                  NEARLY 20% DISCOUNTED VALUE OFF MONTHLY PLAN
                </div>
                <div className="addl-feature">CANCEL ANYTIME</div>

                <div className="div-block-2"></div>
                <div className="subscribe-now">
                  {isAuthenticated() ? (
                    <NavLink
                      className="button in-pricing"
                      exact
                      to="/Profile"
                      data-ix="fade-in-on-load"
                    >
                      Subscribe Now
                    </NavLink>
                  ) : (
                    <Link className="button in-pricing" onClick={login}>
                      Subscribe Now{" "}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionOptions;
