import React, { Component } from "react";

class TermsOfService extends Component {
  render() {
    return (
      <>
        <div className="privacy-policy">
          <h2>TERMS OF SERVICE</h2>
          <p>
            This User Agreement between Authorized User (“You”) and Chance
            Holdings LLC ( www.CallsOrPuts.com ) (“the Site”) and any of our
            Site affiliates, agencies, sponsors, licensees or content partners
            (hereafter, collectively referred to as “CallsOrPuts”, “We”, or
            “Us”) governs your use of these information services and web sites
            (collectively referred to as “the Tools”), including any document,
            content, services, functions or features accessible on or through
            the Site (the “Products”). Your use of the Site and the Tools is
            subject to the terms and conditions hereof. By using the Site or any
            other website owned, operated, licensed, or controlled by Us, you
            agree to be bound by the terms of the User Agreement. Other
            provisions that govern your use of the Site may be set forth in
            online notices appearing in connection with certain Products
            (collectively, the “Additional Terms”), all of which are
            incorporated by reference into this User Agreement. Your use of any
            Products that are subject to Additional Terms constitutes your
            acceptance of the respective Additional Terms.
          </p>
          <p>
            If you do NOT agree with any the following Terms, do NOT use the
            Product associated with them.
          </p>
          <div class="indent">
            <h3>1) ACCEPTANCE</h3>
            <p>
              By registering for or using CallsOrPuts, the user accepts all of
              The terms (the “Terms of Service”). Use of this site will
              constitute The user’s acceptance of all of such terms of service
              and the user Is responsible for checking for updated terms.
            </p>
            <h3>2) SUBSCRIPTION FEE</h3>
            <p>
              You agree to pay the subscription fee for the use of the services
              offered by CallsOrPuts.
            </p>
            <h3>3) USER ACCOUNT</h3>
            <p>
              You agree not to share access to your account and not to
              Redistribute any data you receive through your use of the site or
              Products. If caught breaking these rules legal action might be
              taken Against you and you accept full responsibility to pay the
              fees for Damage determined by CallsOrPuts
            </p>
            <h3>4) DISTRIBUTION</h3>
            <p>
              You may not copy, modify, reproduce, republish, distribute,
              Transmit or use the site CallsOrPuts or the products for
              commercial Or other purposes, except to the extent required in
              order for you to Use the site and the products in the manner
              expressly provided Herein. You may not share your password with
              anyone. Our security System checks for simultaneous logins from
              multiple locations. If it Is found that you are sharing your
              account, the system will Auto-lock the account for 1-7 days as
              required by our data provider Agreements. You may not utilize
              automated systems or software to Extract data from CallsOrPuts.
              You may not store or download data For offline usage.
            </p>
            <h3>5) PERSONAL INFORMATION </h3>
            <p>
              IF REGISTERING FOR USE OF CallsOrPuts THROUGH THE ONLINE
              REGISTRATION PROCESS, THE USER VERIFIES THAT THE INFORMATION
              PROVIDED BY THE USER IS ACCURATE AND CORRECT TO THE BEST OF THE
              USER’S KNOWLEDGE.
            </p>
            <h3>6) COOKIE POLICY </h3>
            <p>
              CallsOrPuts uses cookies, local storage, session storage on your
              Computer acknowledging your acceptance of these terms and
              Conditions. If your computer accepts cookies, you may not be asked
              To review this agreement every time you sign on, but you should
              Understand that your use of the site will always be subject to and
              Pursuant to this agreement. You may review this agreement at any
              Time by clicking on “terms” from our home page.
            </p>
            <h3>7) TRADING IS RISKY = NO REPRESENTATIONS OR WARRANTIES</h3>
            <p>
              Neither CallsOrPuts, its agencies, attorneys, members, affiliates,
              Nor any content partner makes any warranty or representation that
              The information is accurate and timely. You understand that use of
              The site and of the products are undertaken at your own risk. Any
              Information or data provided by CallsOrPuts or CallsOrPuts.Com is
              Not exhaustive or complete. Neither CallsOrPuts nor any content
              Partner assumes any liability for the use or interpretation of any
              Product. The site and the products are provided “as is” without
              Warranty of any kind, either expressed or implied, including, but
              Not limited to, implied warranties with respect to performance,
              Merchantability, fitness for a particular purpose,
              non-infringement Of third party rights, accuracy, omissions,
              completeness, Currentness, or timeliness. Your exclusive remedy
              under this Agreement, if any, for any claim for damages relating
              to your use Of the site or the products, whether on the basis of
              contract, Negligence, or any other basis, will be limited to the
              aggregate Amount of charges paid by you to CallsOrPuts during the
              12 month period preceding the event giving rise to such claim. You
              Acknowledge that provision of the site and content entails the
              Likelihood of some human and machine errors, delays, interruptions
              And losses, including the inadvertent loss of data, server down
              Time, bugs which CallsOrPuts will work to fix as fast as possible.
            </p>
            <h3>8) LIMITATION OF LIABILITY</h3>
            <p>
              CallsOrPuts is not a registered broker-dealer or a registered
              Investment adviser. You understand that this website and content
              is Furnished for your personal, noncommercial, informational
              purposes Only, and that no mention of a particular security in
              this website And content constitutes a recommendation to buy,
              sell, or hold that Or any other security, or that any particular
              security, portfolio of Securities, transaction or investment
              strategy is suitable for any Specific person. We advise everyone
              to know the risks involved with Trading stocks and/or options. We
              encourage every visitor to the Website to do his/her own research
              and to consult with his/her own Financial advisor(s) prior to
              engaging in any investing activities, And to make sure he/she
              knows all the potential risks involved. Any Investment decision
              that results in losses or gains made based on Any information on
              this site, or related services is not the Responsibility of
              CallsOrPuts. CallsOrPuts and all of its contents Are copyrighted.
              The viewer of this site is given a limited license To use its
              contents for personal or internal company use only.
            </p>
          </div>
          <h2>REAL-TIME DATA</h2>
          <p>
            It is impossible to guarantee real-time speed. Data may be delayed
            From 500ms up to 30 seconds dependent on a variety of factors. The
            The majority of incoming data during market hours may be delayed
            Depending on a variety of factors (network connection, network load,
            Data processing time) as well as on behalf of the data provider.
            Every effort is made to get the data to you as quickly as possible
            And with minimal errors/downtime. Chance Holdings LLC
            (CallsOrPuts.com) is not responsible for any loss, any gains, nor
            Any possible liability due to a data outage or delay.
          </p>
          <h2>REFUNDS POLICY</h2>
          <p>
            Since services are non¬-tangible, irrevocable, digital goods; we do
            not issue refunds, which you are solely responsible for
            understanding. During checkout, upon clicking the final submit
            button, your purchase is charged to the credit/debit card that you
            supplied. All sales are final. You cannot cancel a purchase or
            receive a refund for a purchase. You can cancel future recurring
            subscription payments at any time by cancellation through your
            account settings page. You may cancel at any time from your account
            or reach out to our team if you have any questions about the
            process.
          </p>
          <p>
            If you are under a free or discounted trial period, you will be
            charged at the end of your trial unless cancelled. We do not provide
            refunds. All sales are final. it is your responsibility to manage
            your subscription accordingly.
          </p>
          <h2>CallsOrPuts</h2>
          <p>
            Your use of ideas, systems, and/or data provided by CallsOrPuts are
            at your own risk and it is your sole responsibility to evaluate the
            accuracy, completeness, and usefulness of the information. Feel free
            to reach out to our team if you ever think we can help or answer any
            of your questions.
          </p>
        </div>
      </>
    );
  }
}
export default TermsOfService;
