import React from "react";

import CardInput from "./CardInput";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { axiosClient } from "./BASE_URL";

function EditDefaultCard(props) {
  const email = props.profile["email"];
  const name = props.profile["name"];

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitNewCard = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
        name: name,
      },
    });

    if (result.error) {
      alert(result.error.message);
      // Show error in payment form
    } else {
      const roles = props.profile["https://localhost:3000/roles"];
      var cust_id = null;
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].startsWith("cus_")) {
          cust_id = roles[i];
          break;
        }
      }

      const payload = {
        cust_id: cust_id,
        payment_method: result.paymentMethod.id,
      };
      const res = await axiosClient.post("/editcard/", payload);

      const status = res.data.success;

      if (status) {
        props.refreshPage();
      } else {
        alert("Sorry, we could not add this card.");
      }
    }
  };
  const closeEdit = async (event) => {
    props.closeEdit();
  };
  return (
    <>
      <CardInput></CardInput>
      <div className="accountFullRow">
        <button className="accountEdit accountEditButtons" onClick={closeEdit}>
          Cancel Edits
        </button>
        <button
          className="accountEdit accountEditButtons"
          onClick={handleSubmitNewCard}
        >
          Update Card
        </button>
      </div>
    </>
  );
}

export default EditDefaultCard;
