import EditDefaultCard from "./EditDefaultCard";
import React, { Component } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(
//   "pk_test_51HHwoSE0E3xnGADFKxy4jKSzaDZF2Sj75mKxJ0MiIWoAeoGTFR7aYccpYdn2q0p3XYL4Vfzw1gRw13erGiFo1duA003pp7f4zA"
// );
const stripePromise = loadStripe(
  "pk_live_51HHwoSE0E3xnGADFG3CIYIANHlXgR6rbIKlRoCL4nNpbXBd0yvUmerukYztopviJ5fbVgBNxRAT8ugNk40n1kJOs00pI62mDxQ"
);

class EditDefaultCardPage extends Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <EditDefaultCard
          profile={this.props.profile}
          refreshPage={this.props.refreshPage}
          closeEdit={this.props.closeEdit}
        ></EditDefaultCard>
      </Elements>
    );
  }
}

export default EditDefaultCardPage;
