import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Legal extends Component {
  render() {
    return (
      <>
        <footer>
          <p> Created by Traders for Traders</p>
          <hr className={"solid"} />
          <p>
            Legal Disclaimer: Chance Holdings LLC (CallsOrPuts) is not a
            registered investment advisor nor is it licensed as such with any
            federal or state regulatory agency. CallsOrPuts does not manage
            client financial assets in any way. CallsOrPuts is not a fudiciary.
            Information provided and opinions expressed on this website and/or
            on the DISCORD server does not constitute investment advice. The
            ideas expressed on this site and related services are solely the
            opinions of CallsOrPuts and/or the result of technologies you the
            customer use and serve as ideas for educational purposes only. We
            advise everyone to know the risks involved with trading stocks, ETFs
            and options. We encourage every visitor to the website AND/OR
            DISCORD to do his/her own research and to consult with his/her own
            financial advisor(s) prior to engaging in any investing activities,
            and to make sure he/she knows all the potential risks involved. Any
            investment decision that results in losses or gains made based on
            any information on this site or related services is not the
            responsibility of Chance Holdings LLC (CallsOrPuts). Chance Holdings
            LLC (CallsOrPuts) is solely a trading community, a
            technology/data-provider and not a financial service.
          </p>

          <NavLink exact to="/privacy">
            {" "}
            Privacy Policy
          </NavLink>
          <div className={"vertBar"}>|</div>
          <NavLink exact to="/tos">
            {""}
            Terms Of Service
          </NavLink>
        </footer>
      </>
    );
  }
}
export default Legal;
