import React, { useState } from "react";
// MUI Components
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Cookies from "universal-cookie";
// Util imports
import { makeStyles } from "@material-ui/core/styles";
import CardInput from "./CardInputNewSub";

// Stripe
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { axiosClient } from "./BASE_URL";
import { PRODUCTS } from "./stripe/products";
import { useNavigate } from "react-router-dom";

const cardsLogo = ["amex", "discover", "mastercard", "visa"];
const cookies = new Cookies();
const useStyles = makeStyles({
    root: {
        maxWidth: 500,
        marginLeft: "auto",
        marginRight: "auto",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
    },
    div: {
        display: "flex",
        flexDirection: "row",
        alignContent: "flex-start",
        justifyContent: "space-between",
    },
    button: {
        margin: "2em auto 1em",
        background: "linear-gradient(45deg, #886b39 30%, #b59a5f 90%)",
    },
});
function getCoupon() {
    try {
        var cookie = cookies.get("__gtm_campaign_url");
        // eslint-disable-next-line
        if (!cookie)
            return
        var coupon = cookie.split(/[&<>?]/);
        coupon = coupon[3].split(/=/)[1];
        if (coupon) {
            return coupon;
        } else {
            return "";
        }
    } catch (err) {
        console.log(err);
    }
}

function NewSubscription(props) {
    const classes = useStyles();
    // State
    const [email, setEmail] = useState(props.auth.userProfile.email);
    const [name, setName] = useState("");
    const [promoCodeInput, setPromoCodeInput] = useState(getCoupon());
    const [promoCode, setPromoCode] = useState("");
    const [promoID, setPromoID] = useState("");
    const [couponID, setCouponID] = useState("");
    const [plan, setPlan] = useState(null);
    const [total, setTotal] = useState("");
    const [addlText, setAddlText] = useState("");
    const [submitted, setSubmitted] = useState(false);
    // const [submittedCoupon, setSubmittedCoupon] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    var auth0_id = props.auth0_id;
    // auth0_id = auth0_id.split("|")[1];

    const handleCouponCode = async (price, total_2, frequency) => {
        setPromoCode(promoCodeInput);

        if (total_2 === 0)
            return

        const payload = { price, promo_code: promoCodeInput, total: total_2 };

        try {
            const res = await axiosClient.post(
                "/applycoupon/",
                payload
            );
            const valid_coupon = res.data.valid_coupon;
            if (!plan) {
                setAddlText("Please select a plan to continue.");
                return;
            }
            if (
                !valid_coupon &&
                promoCodeInput !== "" &&
                promoCodeInput !== undefined &&
                promoCodeInput !== "SMARTMONEY"
            ) {
                alert("The coupon doesn't exist or is not applicable to the selected plan.");
                return;
            }
            if (promoCodeInput === "SMARTMONEY") {
                setAddlText("You will recieve a 14 day free trial.");
                return;
            }
            if (!valid_coupon) {
                return;
            }

            const coupon_id = res.data.coupon_id;
            setCouponID(coupon_id);

            const promo_id = res.data.promo_id;
            setPromoID(promo_id);
            const old_total = total;
            const new_total = res.data.adjusted_price;
            setTotal(new_total);


            
            const repeating = res.data.repeating;
            console.log({ repeating})
            if (repeating === "once") {
                setAddlText(
                    "This discount will only apply to your first " +
                    frequency +
                    ". After that point you will be charged $" +
                    old_total +
                    "/" +
                    frequency +
                    "."
                );
            } else if (repeating === "forever") {
                setAddlText(
                    "This discount will be automatically applied to your bill every " +
                    frequency +
                    "."
                );
            } else {
                const duration = res.data.duration;
                setAddlText(
                    "This discount will apply to the first " +
                    duration +
                    " " +
                    frequency +
                    " of your subscription. After that point you will be charged $" +
                    old_total +
                    "/" +
                    frequency +
                    "."
                );
            }

            // setSubmittedCoupon(false);
        } catch {
            if (total_2 === "" || total_2 === undefined) {
                await setAddlText(
                    "Your coupon will be applied after you select a plan!"
                );
            }
            return;
        }
    };
    async function handleChange(plan) {
        handleCouponCode(plan.product_id, plan.total, plan.frequency)
        setTotal(plan.total);
    }
    const handleSubmitSubscribe = async () => {
        if (!plan) {
            alert('Please choose a valid plan');
            return;
        }
        if (!stripe || !elements || submitted) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setSubmitted(true);
        setLoading(true);
        const roles = props.profile["https://localhost:3000/roles"];
        var stripe_id = null;
        if (roles && roles.length)
            for (let i = 0; i < roles?.length; i++) {
                if (roles[i].startsWith("cus_")) {
                    stripe_id = roles[i];
                    break;
                }
            }

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: email,
                name: name,
            },
        });

        if (result.error) {
            alert(result.error.message);
            // Show error in payment form
            setSubmitted(false);
        } else {
            const payload = {
                email: email,
                name: name,
                payment_method: result.paymentMethod.id,
                auth0_id: auth0_id,
                coupon_id: couponID,
                plan_id: plan.price,
                promo_code: promoCode,
                promo_id: promoID,
                cust_id: stripe_id,
            };
            if (promoCode === "SMARTMONEY") {
                payload["trial_flag"] = true;
            }

            // Otherwise send paymentMethod.id to your server
            const res = await axiosClient.post("/sub/", payload);

            try {
                if (result.data.error === undefined) {
                    throw new Error("No issues");
                }

                alert(
                    "Subscription failed. Please try again. If the problem persists, please contact support."
                );
                setSubmitted(false);
            } catch (error) {
                const { client_secret, status, cust_id, sub_status } = res.data;

                const auth0_url =
                    "https://dev-7ahtz1y0.us.auth0.com/api/v2/users/";

                if (
                    (status === "requires_action" ||
                        sub_status === "incomplete") &&
                    client_secret !== "No client secret needed"
                ) {
                    stripe
                        .confirmCardPayment(client_secret)
                        .then(function (result) {
                            if (result.error) {
                                // Display error message in your UI.
                                // The card was declined (i.e. insufficient funds, card has expired, etc)
                                alert(result.error.message);
                                const id_info = {
                                    auth0_id: auth0_id,
                                    auth0_url: auth0_url,
                                    stripe_id: cust_id,
                                    status: sub_status,
                                    cancel_at_end: false,
                                    name: name,
                                    total: total,
                                };
                                cookies.set("customer", cust_id);
                                cookies.set("total", total);

                                axiosClient.post("/auth/", id_info);
                            } else {
                                // Show a success message to your customer
                                // Make a PATCH request to AUTH0 with stripe cust_id and subscription status

                                const id_info = {
                                    id: result.id,
                                    auth0_id: auth0_id,
                                    auth0_url: auth0_url,
                                    stripe_id: cust_id,
                                    status: sub_status,
                                    cancel_at_end: false,
                                    name: name,
                                    total: total,
                                };
                                cookies.set("customer", cust_id);
                                cookies.set("total", total);

                                axiosClient.post("/auth/", id_info);
                                setLoading(false);

                                navigate("/ThankYou");
                            }
                        });
                } else {
                    // Make a PATCH request to AUTH0 with stripe cust_id and subscription status

                    const id_info = {
                        auth0_id: auth0_id,
                        auth0_url: auth0_url,
                        stripe_id: cust_id,
                        status: sub_status,
                        cancel_at_end: false,
                        name: name,
                        total: total,
                    };

                    cookies.set("customer", cust_id);
                    cookies.set("total", total);

                    await axiosClient.post("/auth/", id_info);

                    navigate("/ThankYou");
                }
            }
        }
        setLoading(false);
    };

    return (
        <Card className={classes.root}>

            <CardContent className={classes.content}>
                <div>
                    <h4>Subscribe Now</h4>
                    {cardsLogo.map((e) => (
                        <img
                            key={e}
                            src={`./cards/${e}.png`}
                            alt={e}
                            width="50px"
                            align="bottom"
                            style={{ padding: "0 5px" }}
                        />
                    ))}
                </div>
                <select
                    className="dropdown-menu"
                    onChange={(e) => {
                        const planName = e.target.value;
                        const plan = PRODUCTS[planName];
                        handleChange(plan);
                        setPlan(plan);
                    }}
                >
                    <option value="">Select Plan</option>
                    <option value="monthly">
                        Monthly Plan ($65/Month)
                    </option>
                    <option value="quarterly">
                        Quarterly Plan ($175/Quarter)
                    </option>
                    <option value="yearly">
                        Annual Plan($625/Year)
                    </option>
                </select>
                <TextField
                    label="Account Email"
                    id="outlined-email-input"
                    helperText={
                        "This is the email you'll recieve updates and receipts on. If you wish to use a different email, please register with a different email."
                    }
                    margin="normal"
                    variant="outlined"
                    type="email"
                    value={email}
                    disabled={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <TextField
                    label="Name"
                    id="outlined-name-input"
                    margin="normal"
                    variant="outlined"
                    type="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                />

                <CardInput></CardInput>
                <div className="coupon-container">
                    <TextField
                        label="PROMOCODE"
                        id="outlined-coupon-input"
                        helperText={"Coupon Code"}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        defaultValue={promoCodeInput}
                        value={promoCodeInput}
                        onChange={(e) => setPromoCodeInput(e.target.value)}
                        halfWidth
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={(e) => handleChange(plan)}
                    >
                        Apply{" "}
                    </Button>
                </div>
                {addlText !== "" ? (
                    <div className="discounted-text">
                        <p>Total: ${total}</p>
                        <p>{addlText}</p>
                    </div>
                ) : (
                    <p>Total: ${total}</p>
                )}
                <div className={classes.div}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={async () => {
                            setLoading(true);
                            try {
                                await handleSubmitSubscribe();
                            } catch (err) {
                                console.log(`[ERR] Subscription handler error`)
                                console.log(err);
                            } 
                            setLoading(false);
                        }}
                        disabled={loading}
                    >
                        {loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loading && (
                            <span>Processing your subscription...</span>
                        )}
                        {!loading && <span>Submit</span>}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
}

export default NewSubscription;
