
export const PRODUCTS = {
    "monthly": {
        product_id: "prod_OuREmkW00Hz5Rj",
        total: 65, 
        price: "price_1O6cM8E0E3xnGADF6n2I4rEl",
        frequency: 'month'
    },
    "yearly": { 
        total: 625,
        product_id: 'prod_OuRFuWJnqTZnws',
        price: 'price_1O6cNPE0E3xnGADFd6h9hFgQ',
        frequency: 'year'
    },
    'quarterly': {
        total: 175,
        product_id: 'prod_OuRH0iyhJd0OZS',
        price: 'price_1O6cOuE0E3xnGADFcTl6kh6O',
        frequency: 'quarter'
    }
}