
import { useState } from "react";
import { axiosClient } from "../../BASE_URL";
import { Table, watchlistColumns } from "./Table";
import WatchlistToggle from "../../images/back_button.png";
import { isTodayLatest as isDateLatest } from "../../lib/date";


export function getOptionsRowKey(row) {
    return `${row.ticker}-${row.strike_price}-${row.date_expiration}`;
}

export function isInWatchlist(row, watchlist) {
    const rowKey = getOptionsRowKey(row);
    if (Array.isArray(watchlist) && watchlist.length > 0) {
        return watchlist?.some((item) => getOptionsRowKey(item) === rowKey);
    }
    console.log('[CRIT WARNING] watchlist is not an array or is empty', typeof watchlist)
    console.log(JSON.stringify(watchlist));
    return false;
}

export class OptionsWatchlist {

    constructor(userId, setState) {
        this.userId = userId;
        this.currentList = [];
        this.originalValMap = new Map();
        this.rowUpdateMap = {};
        this.setState = setState;
        this.keySet = new Set();
    }

    generateKeySet() {
        this.keySet = new Set();
        this.currentList.forEach(row => {
            const key = getOptionsRowKey(row);
            this.keySet.add(key);
        })
    }

    async getWatchlist() {
        const resp = await axiosClient.get("/watchlist/options", {
            params: { id: this.userId }
        })
        this.currentList = resp.data['watchlist'] ?? [];
        console.log('Initial options watchlist :')
        console.log(this.currentList)
        this.currentList.forEach(row => {
            const rowKey = getOptionsRowKey(row)
            this.originalValMap.set(rowKey, row);
        })
        this.generateKeySet();
        return this.currentList;
    }

    async fetchUpdates() {

        const promises = this.currentList.map(row =>
            axiosClient.post("/options/volume_update", {
                data: {
                    ticker: row.ticker,
                    strike_price: row.strike_price,
                    put_call: row.put_call,
                    expiration_date: row.date_expiration,
                    frozen_date: row.date
                }
            })
        )
        const result = await Promise.all(promises)

        for (let i = 0; i < result.length; i++) {
            const update = result[i].data;
            const row = this.currentList[i];
            const key = getOptionsRowKey(row)
            const updateObj = ["price", "volume", "open_interest"].map(key => {
                return {
                    name: key,
                    original: row[key],
                    ...update[key]
                }
            })
            console.log('update obj')
            console.log(updateObj);
            this.rowUpdateMap[key] = updateObj
        }
        console.log('option watchlist update:')
        console.log(this.rowUpdateMap)
        this.rerenderUpdate();
    }

    /**
     * Checks and updates the watchlist volume and open interest, if the row is present in options watchlist
     * @param {*} row a table row 
     * @returns {boolean} `foundMatch`: returns a boolean indicating whether this row contains an option that is currently in the watchlist
     */
    checkRow(row) {
        const key = getOptionsRowKey(row);
        if (!this.keySet.has(key)) {
            console.log('failed key:', key)
            return { active: false, emoji: false };
        }
        let update = this.rowUpdateMap[key];
        const shouldUpdate = isDateLatest(row.date)
        console.log('found rows')
        if (shouldUpdate) {
            const newUpdate = [
                {
                    ...update[2],
                    name: "price",
                    latest: row.price
                },
                {
                    ...update[0],
                    name: "volume",
                    latest: row.volume,
                },
                {
                    ...update[1],
                    name: "open_interest",
                    latest: row.open_interest,
                }
            ]
            console.log('updated latest for ', key, update);
            update = newUpdate
            this.rowUpdateMap[key] = newUpdate;
        }
        const total = update[0].latest + update[0].previous_sum;
        console.log('Check row result for ', key);
        console.log({ update, total, og: update[0].original })
        const hasDoubleVolume = update[0].original <= (total / 2);
        return { active: true, emoji: hasDoubleVolume };
    }

    rerenderUpdate() {
        const newObj = { ...this.rowUpdateMap }
        this.setState({ options_watchlist_updates: newObj });
    }

    async deleteRow(row) {
        const key = getOptionsRowKey(row);
        const newList = this.currentList.filter(row1 => getOptionsRowKey(row1) !== key);
        this.currentList = newList;
        this.syncWatchList();
    }

    async syncWatchList() {
        this.setState({ options_watchlist: this.currentList.map(row => row) });
        const resp = await axiosClient.post("/watchlist/options/update", {
            id: this.userId, options_watchlist: this.currentList
        })
        console.log('synced watch list')
        if (!resp.data.Success)
            throw new Error("Failed to update watchlist!")
        await this.fetchUpdates();
        this.generateKeySet();
    }

    async addOption(row) {
        this.currentList.push(row.original);
        await this.syncWatchList();
        // trigger react update
        return this.currentList;
    }
}

export default function OptionsWatchListDrawer({ watchlist, optionsWatchlist, updates }) {
    const [toggle, setToggle] = useState(false);
    return <div className={`optionsWatchlist ${toggle ? "toggled" : ""}`}>
        <div className="optionsHeader" onClick={() => setToggle(!toggle)}>
            <h4 >
                OPTIONS WATCHLIST
            </h4>
            <img
                src={WatchlistToggle}
                width={"15px"}
                alt="watchlist_toggle"
                className={`optionsArrow ${toggle ? "toggled" : ""}`}
            />
        </div>

        <div className="optionsInner">
            <Table
                data={watchlist}
                columns={watchlistColumns}
                optionsWatchlist={optionsWatchlist}
                optionsWatchlistUpdate={updates}
                deleteOptionFn={(row) => optionsWatchlist.deleteRow(row)}
                hidden={['date', 'sentiment_flipped']}
            />
        </div>

    </div>
}