import Axios from "axios";

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

console.log({ BASE_URL })
export const FRONTEND_BASE_URL = process.env.FRONTEND_BASE_URL || "https://callsorputs.com";

export const axiosClient = Axios.create({
    baseURL: BASE_URL
})
