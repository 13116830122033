import React from "react";
import { useImperativeHandle } from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import moment from "moment-timezone";
import NewsShortcut from "../../images/news1_noBG.png";
import { getOptionsRowKey } from "./OptionWatchlist";

function filterRange(rows, id, filterValue) {
    if (!filterValue || filterValue?.length < 2)
        return rows;
    const [min, max] = filterValue
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return (min === undefined || min <= rowValue) && (max === undefined || rowValue <= max);
    });
}

function getNormalizedValue(data) {
    return data.trim().toLowerCase();
}

function filterTicker(rows, id, filterValue) {
    if (!filterValue)
        return rows;
    const { type, value } = filterValue;
    if (type === "quick") {
        console.log({ value });
        const filterVal = getNormalizedValue(value);
        return rows.filter((row) => {
            const rowValue = getNormalizedValue(row.values[id]);
            return rowValue === filterVal;
        });
    }
    const filterVal = value.map(getNormalizedValue);
    return rows.filter((row) => {
        const rowValue = getNormalizedValue(row.values[id]);
        return filterVal.includes(rowValue);
    });
}

function filterRangeDate(rows, id, filterValue) {
    const [min, max] = filterValue
    
    const result = rows.filter((row) => {
        const rowValue = row.values[id];
        let modified = rowValue;
        if (rowValue.split("/").length === 2 && row.values.date) {
            let year = row.values.date.split("-")[0];
            modified += `/${year}`;
        }

        const date = moment(modified);
        if (min && max)
            return date.isBetween(min, max, null, '[]')
        if (min)
            return date.isSameOrAfter(min)
        if (max)
            return date.isSameOrBefore(max)
        return true;
    });
    return result;
}

function filterRedFlagRows(rows, id, filterValue) {
    return rows.filter((row) => {
        const isRedFlag = row.values.sentiment_flipped !== 0;
        return isRedFlag === filterValue;
    })
}



function toCurrency(numberString) {
    try {
        let number = Number(numberString);
        number = number.toFixed(0);
        number = parseFloat(number);
        return number.toLocaleString("USD");
    } catch (err) {
        return "Loading";
    }
}

function add_decimal(numberString) {
    try {
        let number = Number(numberString);
        number = number.toFixed(2);
        return number;
    } catch (err) {
        return "Loading";
    }
}

function createSymbolCheckers() {
    return [
        {
            symbol: '🏦',
            checker: (row) => row.values.earnings_date,
        },
        {
            symbol: '🚩',
            checker: (row) => row.values.sentiment_flipped,
        },
    ]
}


function filterNonEmpty(rows, id, filterValue) {
    if (!filterValue)
        return rows;
    return rows.filter((row) => {
        const rowValue = row.values[id]?.trim();
        return !!rowValue;
    });
}

export const tableColumns = [
    {
        Header: "TIME (EST)",
        accessor: "time",
        disableFilters: true,
        canSort: true,
    },
    {
        Header: "TICKER",
        accessor: "ticker",
        filter: filterTicker,
        canSort: true,
        disableFilters: true,
    },
    {
        Header: "EARNINGS",
        accessor: "earnings_date",
        filter: filterNonEmpty,
        disableFilters: true,
        canSort: true,
    },
    {
        Header: "SPOT",
        accessor: "spot",
        disableFilters: true,
        canSort: true,
        Cell: (props) => (
            <div>
                {" "}
                {props.value !== "loading" ? (
                    <span>{add_decimal(props.value)}</span>
                ) : (
                    <span>loading</span>
                )}{" "}
            </div>
        ),
    },

    {
        Header: "STRIKE",
        accessor: "strike_price",
        filter: filterRange,
        canSort: true,
        disableFilters: true,
        Cell: (props) => (
            <div>
                {" "}
                {props.value !== "loading" ? (
                    <span>{add_decimal(props.value)}</span>
                ) : (
                    <span>loading</span>
                )}{" "}
            </div>
        ),
    },
    {
        Header: "CALL/PUT",
        accessor: "put_call",
        disableFilters: true,
    },
    {
        Header: "EXPIRATION",
        accessor: "date_expiration",
        canSort: true,
        filter: filterRangeDate,
        disableFilters: true,
    },
    {
        Header: "DTE",
        accessor: "DTE",
        canSort: true,
        disableFilters: true,
    },

    {
        Header: "COST",
        accessor: "cost_basis",
        filter: filterRange,
        canSort: true,
        disableFilters: true,
        Cell: (props) => (
            <div>
                {" "}
                {props.value !== "loading" ? (
                    <span>${toCurrency(props.value)}</span>
                ) : (
                    <span>loading</span>
                )}{" "}
            </div>
        ),
    },

    {
        Header: "% MOVE",
        accessor: "percent_move",
        canSort: true,
        disableFilters: true,
        filter: filterRange
    },
    {
        Header: "SIZE",
        accessor: "size",
        canSort: true,
        disableFilters: true,
    },
    {
        Header: "@",
        accessor: "blank",
        canSort: false,
        disableFilters: true,
    },

    {
        Header: "PRICE",
        accessor: "price",
        canSort: true,
        disableFilters: true,
        filter: filterRange,
        Cell: (props) => (
            <div>
                {" "}
                {props.value !== "loading" ? (
                    <span>{add_decimal(props.value)}</span>
                ) : (
                    <span>loading</span>
                )}{" "}
            </div>
        ),
    },

    {
        Header: "VOLUME",
        accessor: "volume",
        filter: filterRange,
        disableFilters: true,
    },
    {
        Header: "OI",
        accessor: "open_interest",
        disableFilters: true,
    },
    {
        Header: "TYPE",
        accessor: "option_activity_type",
        canSort: false,
        disableFilters: true,
    },
    {
        Header: "CHANCE AI",
        accessor: "aggressor_ind",
        canSort: true,
        filter: filterRange,
        disableFilters: true,
    },
    {
        Header: "SENTIMENT",
        accessor: "sentiment",
        canSort: true,
        disableFilters: true,
    },
    {
        Header: "CHAN_FILTER",
        accessor: "chan_filter",
        canSort: false,
        disableFilters: true,
    },
    {
        Header: "fetch_date",
        accessor: "date",
        show: false,
    }, 
    {
        Header: "sentiment_hidden_field",
        accessor: "sentiment_flipped",
        filter: filterRedFlagRows,
        show: false,
    }
];


const optionsWatchlistColumnsOmit = ["", "CHANCE AI", "SENTIMENT", "CHAN_FILTER"]
const allColumns = tableColumns;

export const watchlistColumns = allColumns.filter(col => !optionsWatchlistColumnsOmit.includes(col.Header))
console.log('option watchlist columns')
console.log({ watchlistColumns })

function getRowId(row) {
    return row.id
}

export const Table = React.forwardRef(
    (
        {
            columns,
            data,
            onOpenModal,
            hidden,
            optionsWatchlist,
            optionsWatchlistUpdate,
            date,
            addOptionToWatchlistFn,
            deleteOptionFn
        },
        ref
    ) => {
        const filterTypes = React.useMemo(
            () => ({
                text: (rows, id, filterValue) => {
                    return true;
                },
            }),
            // eslint-disable-next-line
            []
        );

        const defaultColumn = React.useMemo(
            () => ({
                // Let's set up our default Filter UI

            }),
            []
        );
        const instance = useTable(
            {
                columns,
                defaultColumn,
                filterTypes,
                data,
                autoResetFilters: false,
                autoResetSortBy: false,
                autoResetPage: false,
                initialState: {
                    pageIndex: 0,
                    pageSize: 30,
                    hiddenColumns: hidden,
                },
                getRowId,
            },
            useFilters,
            useSortBy,
            usePagination
        );
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            visibleColumns,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            prepareRow,
            state: { pageIndex, pageSize },
        } = instance;

        // return table instance
        useImperativeHandle(ref, () => instance);

        const Even = (i) => {
            if (i % 2 === 0) return true;
            else return false;
        };

        const symbolChecker = createSymbolCheckers();

        // Render Data Table UI
        return (
            <>
                <table {...getTableProps()} className="stockTable" id="stockTable">
                    <thead className="optionsTableHeaders">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        <span
                                            {...column.getSortByToggleProps()}
                                        >
                                            {column.render("Header")}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽 "
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </span>
                                    </th>
                                ))}
                                <th>
                                    {/* For Add to watchlist button */}
                                </th>
                            </tr>
                        ))}
                        <tr>
                            <th
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: "left",
                                }}
                            ></th>
                        </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, index) => {
                            prepareRow(row);
                            let isInWatchlist = { active: false, emoji: false };
                            if (optionsWatchlist) {
                                isInWatchlist = optionsWatchlist.checkRow(row.values);
                                // isInWatchlist = { active: true, emoji: true }
                            }
                            return (
                                <>
                                    <tr
                                        {...row.getRowProps()}
                                        className={
                                            row.values.put_call +
                                            " " +
                                            row.values.chan_filter +
                                            " " +
                                            (!Even(index) && " even")
                                        }
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td>
                                                    {cell.column.Header ===
                                                        "TIME (EST)" && (
                                                            <>
                                                                <button
                                                                    onClick={() =>
                                                                        onOpenModal(
                                                                            row.cells[1]
                                                                                .value
                                                                        )
                                                                    }
                                                                    style={{
                                                                        backgroundColor:
                                                                            "transparent",
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    <img
                                                                        alt="News"
                                                                        src={
                                                                            NewsShortcut
                                                                        }
                                                                        style={{
                                                                            width:
                                                                                "20px",
                                                                            height:
                                                                                "20px",
                                                                        }}
                                                                    />
                                                                </button>
                                                                {symbolChecker.map(({ symbol, checker }) => checker(row) ? (
                                                                    <span>
                                                                        {symbol}
                                                                    </span>
                                                                ) : <span>
                                                                    &#xfeff;
                                                                    &#xfeff;
                                                                </span>)}
                                                                {
                                                                    isInWatchlist.emoji ?
                                                                        <span>
                                                                            😱
                                                                        </span> :
                                                                        <span>
                                                                            &#xfeff;
                                                                            &#xfeff;
                                                                        </span>
                                                                }
                                                            </>
                                                        )}
                                                    <div
                                                        {...cell.getCellProps([
                                                            {
                                                                className:
                                                                    cell.column.id,
                                                            },
                                                        ])}
                                                    >
                                                        { }
                                                        {cell.render("Cell")}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                        {addOptionToWatchlistFn && <td>
                                            <button className="whiteBg" onClick={() => addOptionToWatchlistFn(row)}>+</button>
                                        </td>}
                                        {deleteOptionFn && <td>
                                            <button className="btn danger" onClick={() => deleteOptionFn(row.values)}>Delete</button>
                                        </td>}

                                    </tr>
                                    {optionsWatchlistUpdate && getOptionUpdates(row, optionsWatchlistUpdate)}
                                </>
                            );
                        })}
                    </tbody>
                </table>
                <div className={"pagination"}>
                    <button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {"<<"}
                    </button>{" "}
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {"<"}
                    </button>{" "}
                    <span>
                        Page{" "}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{" "}
                    </span>
                    <span>
                        | Go to page:{" "}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "100px" }}
                        />
                    </span>{" "}
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}
                    </button>{" "}
                    <button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </button>{" "}
                </div>

            </>
        );
    }
);

function getOptionUpdates(row, optionsWatchlistUpdate) {
    const rowKey = getOptionsRowKey(row.values);
    console.log(rowKey)
    console.log(optionsWatchlistUpdate)
    const update = optionsWatchlistUpdate[rowKey];
    if (!update)
        return

    return <tr>
        {row.cells.slice(4).map(() => <td></td>)}
        {update.map(val => {
            const latest = val.latest ?? 0;
            const prev_sum = val.previous_sum ?? 0;
            const updated = latest + prev_sum;
            const decreased = val.original > updated;
            let percentageStr = "";
            if (decreased) {
                percentageStr += "-";
            } else {
                percentageStr += "+";
            }
            const percentage = (Math.abs(1 - (updated / val.original)) * 100);
            if (Number.isNaN(percentage)) {
                percentageStr += "0.0";
            } else {
                percentageStr = percentage.toFixed(1);
            }
            return <td>
                <span className={`optionsInterest ${decreased ? "decreased" : "increased"}`}>
                    {updated} ({percentageStr}%)
                </span>
            </td>
        })}
    </tr>
}
