import React, { Component } from "react";
import ChanFish from "./images/video0.mp4";
import egg from "./images/eggs.jpg";

class Fish extends Component {
  render() {
    return (
      <>
        <h1> THE OG CREATORS </h1>
        <p>
          Site Navigation, User Authentication, Data Table by Sohil Kollipara
        </p>
        <p>
          {" "}
          Subscription {"&"} Billing, Account Management, Site Design by Arnav
          Pangasa{" "}
        </p>
        <p>Option Data Processing and Management by Girish Hari</p>

        <a href="https://www.reddit.com/r/wallstreetbets/">
          A Link to our lord Chan Fish's home
        </a>

        <h2> These are some fish Chan caught at 3 am </h2>
        <video loop autoPlay data-reactid=".0.1.0.0">
          <source type="video/mp4" data-reactid=".0.1.0.0.0" src={ChanFish} />
        </video>
        <img src={egg} alt="egg" className="center"></img>
      </>
    );
  }
}
export default Fish;
