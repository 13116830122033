

export class FilterController {
    values = {}

    constructor(tableInstanceRef, initialValues, setState) {
        this.tableInstance = tableInstanceRef;
        this.values = initialValues;
        this.setState = setState;
    }

    setFilter(column, value) {
        if (value === null || value === undefined) {
            return
        }
        this.values[column] = value
        this.tableInstance.current.setFilter(column, value);
    }

    setFilterRange(column, min, max) {
        this.setFilter(column, [min, max]);
    }

    setRangeMin(column, min) {
        const max = this.values[column][1];
        this.setFilter(column, [min, max]);
    }

    setRangeMax(column, max) {
        const min = this.values[column][0];
        this.setFilter(column, [min, max]);
    }
}