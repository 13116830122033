import React, { Component } from "react";
import { Navigate } from "react-router-dom";

class Callback extends Component {
  constructor() {
    super()
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    //auth if expected values in URL
    if (/access_token|id_token|error/.test(this.props.location.hash)) {
      this.props.auth.handleAuthentication();
      this.setState({ loading: false });
    } else {
      throw new Error("Invalid callback Error");
    }

  }
  render() {
    return this.state.loading ? <h4>Logging you in...</h4> : <Navigate to="/" />
  }
}
export default Callback;
