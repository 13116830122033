import React, { Component } from "react";
// import tableIMG from "./images/table.png";
// import thought from "./images/thought_bubble.png";
// import mag from "./images/magnify.png";
// import eye from "./images/eye_transparent.png";;
import SubscriptionOptions from "./SubscriptionOptions";
import Logo from "./images/tableComputer.png";
import WallStreet from "./images/vizualize_wall_street.png";
import Solutions from "./images/Simple_solutions.png";
import Decision from "./images/decision_making.png";
import Historical from "./images/history3.png";
import Info from "./images/news_glow2.png";
import Community from "./images/discord_glow.png";
// import TagManager from "react-gtm-module";

import Benzinga from "./images/Benzinga-logo-white.png";
import Fox from "./images/fox_icon.png";
import Yahoo from "./images/YahooFinanceLogoWhite.png";

// const tagManagerArgs = {
//   gtmId: "GTM-P5TKQ2LP",
// };

//import tableVid from "./images/video0.mp4";

const features = [
  {
    image: WallStreet,
    title: "Visualize Wall Street",
    description:
      "Do not miss out on what the wall street experts are doing. Sweeps are smart routed orders that go across multiple exchanges to fill orders",
  },
  {
    image: Solutions,
    title: "Simple Solutions",
    description:
      "With a single click, easily select and filter what you want to see to help you execute that trade",
  },
  {
    image: Decision,
    title: "Decision Making",
    description:
      "Make a decision with fact-based data, aggressive trade setups, and state of the art inside vision",
  },
  {
    image: Historical,
    title: "Historical Data",
    description:
      "Visualize trends easily with quick and simple access to past data",
  },
  {
    image: Info,
    title: "All Relevant Information",
    description:
      "Instantly switch to up-to-date News and Earning Reports to make the best informed choices possible",
  },
  {
    image: Community,
    title: "Active Community",
    description:
      "Connect with like-minded traders in our interactive discord server of over 12,000 participants",
  },
];

// const plans=[
//   {
//     title:"Monthly",
//     price:"$65",
//     per:"/month",
//     style:"planSilver"
//   },
//   {
//     title:"Quarterly",
//     price:"$175",
//     per:"/quarter",
//     style:"planSilver"
//   },
//   {
//     title:"Annually",
//     price:"$625",
//     per:"/year",
//     style:"planGold"
//   },
// ]

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      error: "",
      status: "",
    };
  }

  render() {
    const { isAuthenticated, login } = this.props.auth;
    if (isAuthenticated() && this.state.profile !== null) {
      this.props.auth.getProfile((profile, error) =>
        this.setState({ profile, error })
      );
    }
    return (
      <div id="home-content">
        <div className="homepage">
          <div className="homepageBackground">
            <div className="homepageIntro">
              <p className="homepageIntroTitle">
                State Of The Art Intellectual Trading Platform
              </p>
              <p className="homepageIntroSubtitle">
                Created By Traders For Traders
              </p>
              <p className="homepageIntroBody">
                Calls or Puts is focused on developing the trading world's
                leading data platform through Real-Time option sweeps, with
                state of the art filters that removes useless sweeps providing
                you with an insight never seen before in the institutional world
              </p>
              <button className="homepageIntroButton" onClick={login}>
                Subscribe Now
              </button>
            </div>

            <div className="asSeenOn">
              <p className="asSeenOnText">AS SEEN ON:</p>
              <div className="asSeenOnContent">
                <div className="asSeenOnLogos">
                  <img
                    className="asSeenOnImages"
                    src={Benzinga}
                    alt="Benzinga"
                  />
                  <img className="asSeenOnImages" src={Fox} alt="Fox" />
                  <img
                    className="asSeenOnImages"
                    src={Yahoo}
                    alt="Yahoo_Finance"
                  />
                </div>
              </div>
            </div>

            <img className="optionsPreview" src={Logo} alt="Options_Preview" />
          </div>

          <div className="line"></div>

          <div className="features">
            <p className="featuresTitle">Cutting Edge Features</p>
            <p className="featuresBody">
              Calls or Puts is focused on developing the trading world's leading
              data platform through Real-Time option sweeps, with state of the
              art filters that removes useless sweeps providing you with an
              insight never seen before in the institutional world - all with a
              much more simple and straight to the point platform unlike any
              other. Calls or Puts can be described as a platform actually
              tailored to traders.
            </p>

            {/* Map */}
            {/* <div>
              <img src="" alt="image" />
              <p>
                Visualize Wall Street
              </p>
              <div></div>
              <p>
                Do not miss out on what the wall street experts are doing. Sweeps are smart routed orders that go across multiple exchanges
                to fill orders
              </p>
            </div> */}
            <div className="featuresFlexbox">
              {features.map((f) => (
                <div className="featuresElement">
                  <img src={f.image} alt="Features" />
                  <p className="featuresFlexboxTitle">{f.title}</p>
                  <div className="featuresLine"></div>
                  <p className="featuresFlexboxBody">{f.description}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="line"></div>

          <SubscriptionOptions auth={this.props.auth} {...this.props} />
        </div>
      </div>
    );
  }
}
export default Home;
