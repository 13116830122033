import moment from "moment-timezone"

const timezone = 'America/Toronto';

function getTimezoneDate(date) {
    return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
}

export function getLatestTradingDay() {
    const currentDate = getTimezoneDate(new Date());
    const day = currentDate.getDay();
    const nineThirtyAM = new Date(currentDate);
    nineThirtyAM.setHours(9);
    nineThirtyAM.setMinutes(30);
    const isCurrent = currentDate.getTime() > nineThirtyAM.getTime()
    if (day === 0 || day === 6 || !isCurrent) {
        const yesterday = new Date(currentDate);
        let subtractDays = day === 0 ? 2 : 1;
        if (day === 1)
            subtractDays = 3
        yesterday.setDate(yesterday.getDate() - subtractDays);
        return yesterday;
    }
    return currentDate;
}

function getDateString(date) {
    const dateFormat = "YYYY-MM-DD";
    const dateString = moment(date).tz(timezone).format(dateFormat);
    return dateString;
}

export function isTodayLatest(date) {
    const today = new Date();
    const latestTradingDay = getLatestTradingDay();
    return getDateString(today) === getLatestTradingDay(latestTradingDay)
}