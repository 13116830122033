import React, { Component } from "react";

import NewSubscription from "./NewSubscription.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(
//   "pk_test_51HHwoSE0E3xnGADFKxy4jKSzaDZF2Sj75mKxJ0MiIWoAeoGTFR7aYccpYdn2q0p3XYL4Vfzw1gRw13erGiFo1duA003pp7f4zA"
// );
const stripePromise = loadStripe(
  "pk_live_51HHwoSE0E3xnGADFG3CIYIANHlXgR6rbIKlRoCL4nNpbXBd0yvUmerukYztopviJ5fbVgBNxRAT8ugNk40n1kJOs00pI62mDxQ"
);

class NewSubscriptionPage extends Component {
  state = {
    profile: null,
    err: "",
    access_token: "",
  };

  componentDidMount() {
    this.loadUserProfile();
    this.setAccessToken();
  }

  loadUserProfile() {
    this.props.auth.getProfile((profile, error) =>
      this.setState({ profile: profile, err: error })
    );
  }

  setAccessToken() {
    const at = this.props.auth.getAccessToken();
    this.setState({ access_token: at });
  }

  render() {
    const { profile } = this.state;
    const { access_token } = this.state;
    if (!profile) return null;

    return (
      <Elements stripe={stripePromise}>
        <NewSubscription
          auth0_id={profile.sub}
          access_token={access_token}
          auth={this.props.auth}
          profile={profile}
        ></NewSubscription>
      </Elements>
    );
  }
}

export default NewSubscriptionPage;
