import moment from "moment-timezone"
import { useEffect, useState } from "react";

const timezone = "America/Toronto";
const blacklistCountdown = ["", "TBA"]

export default function EventNotifications({ event, onDismiss }) {
    const [countdown, setCountdown] = useState("00:00:00");
    const [expired, setExpired] = useState(false);
    const { name, time, dismissed } = event;

    useEffect(() => {
        const currentTime = moment().tz(timezone);
        if (blacklistCountdown.includes(time)) {
            return
        }
        const eventTime = moment.tz(time, "hh:mm A", timezone);

        if (eventTime.isBefore(currentTime)) {
            setExpired(true);
        }

        const interval = setInterval(() => {
            const currentTime = moment().tz(timezone);
            const eventTime = moment.tz(time, "hh:mm A", timezone);
            const duration = moment.duration(eventTime.diff(currentTime));

            const hours = duration.hours().toString().padStart(2, "0");
            const minutes = duration.minutes().toString().padStart(2, "0");
            const seconds = duration.seconds().toString().padStart(2, "0");
            const msgParts = []
            if (hours > 0) {
                msgParts.push(hours)
            }
            msgParts.push(minutes, seconds)
            const msg = msgParts.join(":")
            setCountdown("in " + msg);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    return !expired && !dismissed && (
        <div style={{ background: "#19191a", padding: "8px 12px", color: "white", whiteSpace: "nowrap", borderRadius: "8px" }}>
            <h4 style={{ marginTop: "8px", marginBottom: "2px", fontSize: "16px" }}>{name.toUpperCase()}</h4>
            {blacklistCountdown.includes(countdown) ? countdown :
                <span style={{ fontSize: "13px" }}> {countdown} ({time})</span>
            }
            <span style={{ display: "block", cursor: "pointer", fontSize: "11px", marginTop: "8px", color: "red" }} onClick={onDismiss}>
                Dismiss
            </span>
        </div>
    )
}