import React, { Component } from "react";
import { axiosClient } from "./BASE_URL";
import eye from "./images/calendar-white.svg";

class EarningReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            error: "",
            status: "",
            Reports: [],
            upcoming: false,
            pages: [],
            page: 0,
            ticker: "",
            descending: true,
        };
    }

    componentDidMount() {
        this.loadUserProfile();
        this.renderEarnings();
        setInterval(() => {
            this.loadUserProfile();
            this.renderEarnings();
        }, 60000);
    }

    loadUserProfile() {
        if (this.props.auth.isAuthenticated()) {
            this.props.auth.getProfile((profile, error) =>
                this.setState({ profile, error })
            );
        } else {
            this.props.auth.login();
        }
    }

    // renderEarningsTwice = () => {
    //     this.renderEarnings();
    //     this.renderEarnings();
    // }

    renderEarnings = async () => {
        try {
            const earinings_payload = {
                id: this.props.auth.userProfile.sub,
                upcoming: this.state.upcoming,
            };

            const res = await axiosClient.post(
                "/earnings",
                earinings_payload
            );
            const reports = res.data.earnings.earnings;
            //58
            this.setState({ pages: [] });
            this.setState({
                Reports: reports,
            });
            this.makePages();
        } catch (err) {
            console.log(err);
        }
    };

    makePages = async () => {
        let pageSkip = 0;
        if (this.state.descending) {
            for (let i = 0; i < this.state.Reports.length; i++) {
                if (
                    this.state.ticker === "" ||
                    this.state.Reports[i].ticker === this.state.ticker
                ) {
                    if (pageSkip >= this.state.page * 60) {
                        this.setState({
                            pages: [...this.state.pages, this.state.Reports[i]],
                        });
                    } else {
                        pageSkip++;
                    }
                    if (this.state.pages.length === 60) {
                        i = this.state.Reports.length;
                    }
                }
            }
        } else {
            for (let i = this.state.Reports.length - 1; i >= 0; i--) {
                if (
                    this.state.ticker === "" ||
                    this.state.Reports[i].ticker === this.state.ticker
                ) {
                    if (pageSkip >= this.state.page * 60) {
                        this.setState({
                            pages: [...this.state.pages, this.state.Reports[i]],
                        });
                    } else {
                        pageSkip++;
                    }
                    if (this.state.pages.length === 60) {
                        i = -1;
                    }
                }
            }
        }
    };

    changeRoute = () => {
        if (this.props.auth.isAuthenticated()) {
            this.props.history.push("profile");
        } else this.props.auth.login();
    };

    currentButton = () => {
        this.setState({ upcoming: !this.state.upcoming }, this.renderEarnings);
    };

    pageDown = () => {
        if (this.state.page > 0)
            this.setState({ page: this.state.page - 1 }, this.renderEarnings);
    };

    pageUp = () => {
        if (this.state.page < 3)
            this.setState({ page: this.state.page + 1 }, this.renderEarnings);
    };

    tickerChange = (e) => {
        this.setState(
            { ticker: e.toString().toUpperCase() },
            this.renderEarnings
        );
    };

    orderToggle = () => {
        this.setState(
            { descending: !this.state.descending },
            this.renderEarnings
        );
    };

    render() {
        const EarningsTable = () => {
            return (
                <table>
                    {/* <thead>
                        <tr className="reportTableHeaders">
                            <th>Ticker<input type="text" className="reportTableSearchbar" onChange={event => this.tickerChange(event.target.value)}></input></th>
                            <th>Release Date</th>
                            <th>Release Time</th>
                        </tr>
                    </thead> */}
                    <tbody className="reportTableData">
                        {this.state.pages.map((a) => {
                            if (
                                a.ticker != null &&
                                (this.state.ticker === "" ||
                                    a.ticker === this.state.ticker)
                            ) {
                                return (
                                    <tr className="reportTableRow">
                                        <td>
                                            <button className="reportTableButton">
                                                {a.ticker}
                                            </button>
                                        </td>
                                        <td>{a.date}</td>
                                        <td>{a.time}</td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                    </tbody>
                </table>
            );
        };

        return (
            <div className="earningReports">
                <div className="reportsFilters">
                    <button
                        className="reportFilterButton"
                        onClick={this.currentButton}
                    >
                        Current
                    </button>
                    <button
                        className="reportFilterButton"
                        onClick={this.currentButton}
                    >
                        Upcoming
                    </button>
                    {/* <button className="reportFilterButton" onClick={this.upcomingButton}>
                        Upcoming
                    </button> */}
                </div>
                <div className="reportsTable">
                    <table>
                        <thead>
                            <tr className="reportTableHeaders">
                                <th>
                                    Ticker
                                    <input
                                        type="text"
                                        className="reportTableSearchbar"
                                        onChange={(event) =>
                                            this.tickerChange(
                                                event.target.value
                                            )
                                        }
                                        value={this.state.ticker}
                                    ></input>
                                </th>
                                <th>
                                    Release Date{" "}
                                    <img
                                        className="sortToggle"
                                        src={eye}
                                        alt="oops"
                                        onClick={this.orderToggle}
                                    ></img>
                                </th>
                                <th>Release Time</th>
                            </tr>
                        </thead>
                    </table>
                    <EarningsTable />
                </div>
                <div className="pageSelect">
                    <button className="prevPage" onClick={this.pageDown}>
                        {" "}
                        &laquo; Previous{" "}
                    </button>
                    <p>{this.state.page + 1}</p>
                    <button className="nextPage" onClick={this.pageUp}>
                        {" "}
                        Next &raquo;{" "}
                    </button>
                </div>
            </div>
        );
    }
}
export default EarningReports;
