import React, { Component } from "react";
// import Button from "@material-ui/core/Button";
import axios from "axios";
import { axiosClient } from "./BASE_URL";

// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();

class GetSubscriptionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      status: "",
      billDate: "",
    };
    this.getSubStatus = this.getSubStatus.bind(this);
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source();
    this.getSubStatus();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Axios request canceled.");
  }

  async getSubStatus() {
    console.log("profile", this.state.profile);
    const roles = this.state.profile["https://localhost:3000/roles"];
    var cust_id = null;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].startsWith("cus_")) {
        cust_id = roles[i];
        break;
      }
    }

    if (cust_id != null) {
      const payload = { cust_id: cust_id };
      const res = await axiosClient.post(
        "/getsubstatus/",
        payload,
        { cancelToken: this.axiosCancelSource.token }
      );
      console.log("Info on sub", res);
      if (res.data.status === "active") {
        this.setState({ status: "Active" });
        let dateStr = "";
        if (res.data.cancelatend === true) {
          dateStr =
            "Your subscription will be cancelled on " +
            res.data.renewal_or_cancel_date;
        } else {
          dateStr = res.data.renewal_or_cancel_date;
        }
        this.setState({ billDate: dateStr });
      } else {
        this.setState({ status: "Not subscribed" });
      }
    } else {
      this.setState({ status: "Not subscribed" });
    }
  }
  render() {
    return (
      <div>
        <p className="accountInfo accountDetailsInfo">
          {" "}
          {this.state.billDate}{" "}
        </p>
      </div>
    );
  }
}

export default GetSubscriptionStatus;
