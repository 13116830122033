import React, { Component } from "react";
// import Button from "@material-ui/core/Button";

import axios from "axios";
import { Link } from "react-router-dom";
import { axiosClient } from "./BASE_URL";
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();

class GetSubscriptionOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      status: "",
      billDate: "",
      frequency: "",
    };
    this.handleSubmitCancelAtEnd = this.handleSubmitCancelAtEnd.bind(this);
    this.getSubStatus = this.getSubStatus.bind(this);
  }

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source();
    this.getSubStatus();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Axios request canceled.");
  }

  async handleSubmitCancelAtEnd() {
    const roles = this.props.profile["https://localhost:3000/roles"];
    var paying = false;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] === "paying") {
        paying = true;
        break;
      }
    }

    var cust_id = null;
    if (paying) {
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].startsWith("cus_")) {
          cust_id = roles[i];
          break;
        }
      }
      if (cust_id == null) {
        alert(
          "You have no customer id. If you recently purchased a subscription, please log out and log back in."
        );
      }
    } else {
      alert("You are not subscribed, so you cannot unsubscribe.");
    }

    if (cust_id != null) {
      const payload = { cust_id: cust_id };
      const res = await axiosClient.post(
        "/cancelatend/",
        payload
      );
      // We probably want to have some sort of popup warning that's like "ARE YOU SURE YOU WANT TO CANCEL??"
      if (res.data === "No subscription") {
        alert("You are not subscribed, so you cannot unsubscribe.");
      } else {
        alert("Your subscription will terminate at the end of your period.");
        this.props.refreshPage();
      }
    }
  }

  async getSubStatus() {
    console.log("profile", this.state.profile);
    const roles = this.state.profile["https://localhost:3000/roles"];
    var cust_id = null;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].startsWith("cus_")) {
        cust_id = roles[i];
        break;
      }
    }

    if (cust_id != null) {
      const payload = { cust_id: cust_id };
      const res = await axiosClient.post(
        "/getsubstatus/",
        payload,
        { cancelToken: this.axiosCancelSource.token }
      );
      console.log("Info on sub", res);
      this.setState({ frequency: res.data.frequency });
      if (res.data.status === "active") {
        this.setState({ status: "Active" });
        let dateStr = "";

        console.log("frequency", this.state.frequency);
        if (res.data.cancelatend === true) {
          dateStr =
            "Your subscription will be cancelled on " +
            res.data.renewal_or_cancel_date;
        } else {
          dateStr = res.data.renewal_or_cancel_date;
        }
        this.setState({ billDate: dateStr });
      } else {
        this.setState({ status: "Not subscribed" });
      }
    } else {
      this.setState({ status: "Not subscribed" });
    }
  }
  render() {
    return (
      <div className={"flex-column"}>
        <div className={"flex-column-header"}>Subscription Details</div>

        {/* <GetSubscriptionStatus
            profile={profile}
          ></GetSubscriptionStatus>
          {this.props.auth.getPaymentStatus() ? (
            <></>
          ) : (
            <ResubscriptionPage auth={this.props.auth} />
          )} */}

        <div className="accountSubBackground ASB1">
          <p className="accountSubHeader">Monthly</p>
          <p className="accountSubPrice">$65</p>

          <p className="ASC">Real-Time Options Sweeps Data</p>
          <p className="ASC">Simple Filters For Complex Data</p>
          <p className="ASC">Market Overview</p>
          <p className="ASC">Same-Day Customer Support</p>
          <p className="ASC">CANCEL ANYTIME</p>

          {this.state.frequency === "month" ? (
            <button className="accountSubButton">Current Tier</button>
          ) : (
            <> </>
          )}
        </div>

        <div className="accountSubBackground ASB2">
          <p className="accountSubHeader">Quarterly</p>
          <p className="accountSubPrice">$175</p>

          <p className="ASC">Real-Time Options Sweeps Data</p>
          <p className="ASC">Simple Filters For Complex Data</p>
          <p className="ASC">Market Overview</p>
          <p className="ASC">Same-Day Customer Support</p>
          <p className="ASC">Premium Discord Chat Access</p>
          <p className="ASC">10% TOTAL DISCOUNTED</p>
          <p className="ASC">CANCEL ANYTIME</p>

          {this.state.frequency === "quarter" ? (
            <button className="accountSubButton">Current Tier</button>
          ) : (
            <> </>
          )}
        </div>

        <div className="accountSubBackground ASB3">
          <p className="accountSubHeader">Annual</p>
          <p className="accountSubPrice">$625</p>

          <p className="ASC">Real-Time Options Sweeps Data</p>
          <p className="ASC">Simple Filters For Complex Data</p>
          <p className="ASC">Market Overview</p>
          <p className="ASC">Same-Day Customer Support</p>
          <p className="ASC">Premium Discord Chat Access</p>
          <p className="ASC">Unlimited Discord Alerts</p>
          <p className="ASC">20% TOTAL DISCOUNTED</p>
          <p className="ASC">CANCEL ANYTIME</p>

          {this.state.frequency === "year" ? (
            <button className="accountSubButton">Current Tier</button>
          ) : (
            <> </>
          )}
        </div>

        {this.props.auth.getPaymentStatus() ? (
          <div className={"centered-button"}>
            <Link
              className="accountSubCancel"
              onClick={this.handleSubmitCancelAtEnd}
            >
              Cancel Your Subscription?
            </Link>
          </div>
        ) : (
          <> </>
        )}
      </div>
    );
  }
}

export default GetSubscriptionOptions;
